@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.15b3ab5a.eot);
  src:  url(/static/media/icomoon.15b3ab5a.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.b7823ec0.ttf) format('truetype'),
    url(/static/media/icomoon.21ce0157.woff) format('woff'),
    url(/static/media/icomoon.dd9f1fda.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check_circle_outline:before {
  content: "\E91C";
}
.icon-check_circle:before {
  content: "\E921";
}
.icon-times:before {
  content: "\E90A";
}
.icon-times-circle:before {
  content: "\E910";
}
.icon-assessment:before {
  content: "\E90C";
}
.icon-order-history:before {
  content: "\E91E";
}
.icon-hamburger:before {
  content: "\E905";
}
.icon-bell:before {
  content: "\E929";
}
.icon-mail:before {
  content: "\E92E";
  font-size: 0.75rem;
}
.icon-chat:before {
  content: "\E925";
}
.icon-session:before {
  content: "\E926";
}
.icon-lock-alt:before {
  content: "\E911";
}
.icon-clock:before {
  content: "\E901";
}
.icon-percentage_large:before {
  content: "\E902";
}
.icon-cloud-upload:before {
  content: "\E903";
}
.icon-percentage:before {
  content: "\E904";
}
.icon-settings:before {
  content: "\E906";
}
.icon-article1:before {
  content: "\E907";
}
.icon-phone:before {
  content: "\E908";
}
.icon-website:before {
  content: "\E909";
}
.icon-at:before {
  content: "\E90B";
}
.icon-instagram:before {
  content: "\E90D";
}
.icon-trash:before {
  content: "\E90E";
}
.icon-message:before {
  content: "\E90F";
}
.icon-twitter:before {
  content: "\E912";
}
.icon-map:before {
  content: "\E913";
}
.icon-user:before {
  content: "\E914";
}
.icon-info:before {
  content: "\E915";
}
.icon-star:before {
  content: "\E916";
}
.icon-facebook:before {
  content: "\E917";
}
.icon-date-picker:before {
  content: "\E918";
}
.icon-search:before {
  content: "\E919";
}
.icon-bookmark:before {
  content: "\E91A";
}
.icon-audio:before {
  content: "\E91B";
}
.icon-video:before {
  content: "\E91D";
}
.icon-upload:before {
  content: "\E91F";
}
.icon-home:before {
  content: "\E920";
}
.icon-plus-circle:before {
  content: "\E922";
}
.icon-tick:before {
  content: "\E923";
}
.icon-back-arrow:before {
  content: "\E924";
}
.icon-angle-right:before {
  content: "\E927";
}
.icon-angle-left:before {
  content: "\E928";
}
.icon-search-navbar:before {
  content: "\E92A";
}
.icon-plus:before {
  content: "\E92B";
}
.icon-minus:before {
  content: "\E92C";
}
.icon-arrow:before {
  content: "\E92D";
}
.icon-article:before {
  content: "\E900";
}
.icon-menu:before {
  content: "\E9BD";
}
.icon-star-empty:before {
  content: "\E9D7";
}
.icon-arrow-up:before {
  content: "\EA3A";
}
.icon-arrow-right:before {
  content: "\EA3C";
}
.icon-arrow-down:before {
  content: "\EA3E";
}
.icon-arrow-left2:before {
  content: "\EA40";
}
.icon-circle-up:before {
  content: "\EA41";
}
.icon-circle-right:before {
  content: "\EA42";
}
.icon-circle-down:before {
  content: "\EA43";
}
.icon-circle-left:before {
  content: "\EA44";
}
:root {
    --primary: #d4af37;
    --primary-hover: #d4a002;
    --primary-active: #d49400;
}

body{
    font-family: 'Source Sans Pro', sans-serif;
    color: #212121;
}
button, input:focus {
    box-shadow: none !important;
    outline: none !important;
}

.font-bold {
    font-weight: bold;
}
.btn-link {
    color: #d4af37;
    color: var(--primary);
}
.btn-link:hover {
    color: #d4a002;
    color: var(--primary-hover);
}
.assessment-footer .progress {
    background-color: rgba(255,255,255,0.2);
    height: .25rem;
}
.assessment-footer .progress-bar {
    background-color: #fff !important;
}

.bg-primary {
    background-color: #d4af37 !important;
    background-color: var(--primary) !important;
}
.bg-white {
    background-color: #fff !important;
}

.cursor-pointer { cursor: pointer;}

h1, .h1 {
    font-size: 2.25rem;
    font-weight: bold;
}
h2, .h2 {
    font-size: 1.75rem;
    font-weight: bold;
}
.text-primary {
    color: #d4af37 !important;
    color: var(--primary) !important;
}
.text-secondary {
    color: #9c9c9c !important;
}
.text-dark {
    color: #212121 !important;
}
.display1 {
    font-size: 1.3125rem;
}
.display2 {
    font-size: 0.75rem;
}
.display3 {
    font-size: 0.625rem;
}
.font-medium {
    font-weight: 600;
}
.btn-outline-primary {
    color: #212121;
    border-color: #d4af37;
    border-color: var(--primary);
}
.btn-outline-primary:hover,
.btn-outline-primary:active {
    color: #212121 !important;
    background-color: #fff8ec !important;
    border-color: #d4af37 !important;
}
.btn-outline-primary:disabled { color: unset; }
.border-gt {
    border-color: #eaedf3 !important;
}

.btn-outline-secondary {
    color: #212121 !important;
    border-color: #cccaca;
    background-color: #fff !important;
}
.btn-primary {
    color: #fff;
    background-color: #d4af37 !important;
    background-color: var(--primary) !important;
    border-color: #d4af37 !important;
    border-color: var(--primary) !important;
}

/* Skeleton */

.shine {
    background: #f6f7f8;
    background-image: -webkit-gradient(linear, left top, right top, from(#f6f7f8), color-stop(20%, #edeef1), color-stop(40%, #f6f7f8), to(#f6f7f8));
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px; 
    display: inline-block;
    position: relative; 
    
    -webkit-animation-duration: 1s; 
    
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; 
    -webkit-animation-iteration-count: infinite; 
            animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
            animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
}

@-webkit-keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }
    
    100% {
        background-position: 468px 0; 
    }
}

.img-placeholder {
    height: 8rem;
    width: 8rem;
}

.img-placeholder-small {
    height: 7rem;
    width: 7rem;
    min-width: 7rem;
}
.img-placeholder-sm {
    height: 3rem;
    width: 3rem;
}
.img-placeholder-xm {
    height: 2rem;
    width: 2rem;
}
.line-placeholder {
    height: 10px;
    width: 64px;
    margin: 4px 0;
    align-self: center;
}
.line-placeholder-100 {
    width: 100%;
}
.line-placeholder-75 {
    width: 75%;
}
.line-placeholder-50 {
    width: 50%;
}
.line-placeholder-10 {
    width: 10%;
}

.line-placeholder-long {
    width: 200px;
}
.line-placeholder-small {
    width: 25px;
}
.line-placeholder-smaller {
    width: 15px;
}
.line-placeholder-smallest {
    width: 10px;
}
.header-placeholder-h1 {
    height: 28px;
}
.header-placeholder-h2 {
    height: 18px;
}

.text-vertical-truncate {
    /* autoprefixer: off; */
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    word-break: break-word
}

.line-1 {
    -webkit-line-clamp: 1 !important;
}
.line-2 {
    -webkit-line-clamp: 2 !important;
}
.line-3 {
    -webkit-line-clamp: 3 !important;
}

@media (min-width: 992px) {
    .line-lg-1 {
        -webkit-line-clamp: 1 !important;
    }
    .line-lg-2 {
        -webkit-line-clamp: 2 !important;
    }
    .line-lg-3 {
        -webkit-line-clamp: 3 !important;
    }
}

@media (min-width: 768px) {
    .line-md-1 {
        -webkit-line-clamp: 1 !important;
    }
    .line-md-2 {
        -webkit-line-clamp: 2 !important;
    }
    .line-md-3 {
        -webkit-line-clamp: 3 !important;
    }
}
#landing-page section {
  margin-top: 2rem;
}
#landing-banner {
  height: 75vh;
  background-blend-mode: multiply;
  background-color: #00000080;
  background-image: url("/images/Header-Image.jpg");
  -webkit-transition: background-image 2s ease-in;
  transition: background-image 2s ease-in;
  /* background-color: #000; */
  background-size: cover;
  background-position: center;
  color: #fff;
}
#landing-logo {
  position: absolute;
  left: 2.175rem;
  top: 2.75rem;
  height: 1.175rem;
}

#landing-banner .banner-container {
  height: calc(100% - 64px);
  overflow: hidden;
}

#landing-banner .banner-content > h1 {
  font-family: "Playfair Display", serif;
  font-size: 2.5rem;
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 1rem;
}
#landing-banner .banner-content > p {
  line-height: 1.5;
  font-size: 1rem;
  margin: 0;
  padding: 0 22%;
}

#landing-banner .banner-button {
  text-align: center;
  border-radius: 6px;
  box-shadow: 0 2px 14px 0 rgba(193, 193, 193, 0.22);
  background-image: linear-gradient(
    350deg,
    rgb(212, 148, 55),
    rgb(212, 175, 55)
  );
  cursor: pointer;
  padding: 1.25rem;
}

#landing-banner .banner-button h6 {
  font-size: 1.3215rem;
  margin: 0;
  line-height: 1.52;
  color: #fff;
  white-space: nowrap;
  text-decoration: none;
  font-weight: bold;
}
#landing-banner .banner-button p {
  font-size: 0.75rem;
  line-height: 1.5;
}

#landing-banner .banner-card {
  position: absolute;
  left: 8%;
  right: 8%;
  bottom: 1.5rem;
  box-shadow: 0 2px 14px 0 hsla(0, 0%, 76%, 0.22);
  background: #fff;
  border-radius: 15px;
}
#landing-banner .banner-card .banner-card-item a {
  text-decoration: none;
  font-size: 2.25rem;
  text-align: center;
  font-weight: bold;
  color: #342f20;
  margin: 0;
  line-height: 1.2;
}
#landing-banner .banner-card .banner-card-item p {
  margin: 0;
  color: var(--primary);
  text-align: center;
}

.landing-section-header > h4 {
  margin: 0;
  color: #342f20;
  font-size: 1.75rem;
  font-family: "Playfair Display", serif;
  font-weight: bold;
  line-height: 1.2;
}
.landing-section-header > p {
  margin: 0;
  color: #b3b3b3;
}

#focuses-section .focus-item {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
#focuses-section .focus-item p {
  position: absolute;
  margin: 0;
  /* padding: 0 2.5rem; */
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-weight: 600;
  color: #fff;
  line-height: 1.5;
  width: 100%;
  text-align: center;
}
#focuses-section .focus-item img {
  border-radius: 50%;
  height: 10.625rem;
  width: 10.625rem;
}

#landing-register-now {
  background: #876d16;
  color: #fff;
  /* height: 5.75rem; */
}
#landing-register-now p {
  margin-right: 1.875rem;
  white-space: nowrap;
  font-size: 1.125rem;
  font-weight: bold;
}

/* NAVBAR */

.landing-navbar {
  padding-top: 2rem;
  align-items: center;
}
.landing-navbar .nav-logo {
  height: 4.25rem;
  position: absolute;
  bottom: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}
.landing-navbar .navbar-links a,
.landing-navbar .navbar-links .signup {
  font-size: 1rem;
  color: #fff;
  font-weight: 600;
}
.landing-navbar .navbar-links a:not(:last-child) {
  margin-right: 1rem;
}
.landing-navbar .navbar-links .signup {
  padding: 0.35rem 1.75rem;
  border-radius: 40px;
  margin-right: 1rem;
  background: var(--primary);
}
.landing-navbar .navbar-links .signup:hover {
  text-decoration: underline;
}

.landing-navbar .logout-btn {
  background: none;
  font-weight: 600;
  color: #fff;
  border: none;
}

.navbar-search {
  position: relative;
  -webkit-transform-origin: right;
          transform-origin: right;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 2;
  border-radius: 40px;
  background-color: #fff;
  height: 2.5rem;
}
.nav-bordered {
  border-radius: 3px;
  border: 1px solid #dedede;
}
.navbar-search-box svg {
  position: absolute;
  height: 1rem;
  right: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 3;
}
.navbar-search input {
  border: none;
  font-size: 0.825rem;
  color: #212121;
}
.search-drop-down {
  font-size: 0.825rem;
  color: #212121;
  cursor: pointer;
}

.navbar-search .drop {
  min-width: 6rem;
  border-right: 1px solid #ececec;
}

.navbar-search .dropdown {
  z-index: 2;
  top: 1.75rem;
}

.navbar-search ul {
  padding: 0;
  margin-top: 0.25rem;
  background: #fff;
  border-radius: 0.25rem;
  width: 10rem;
  border: 1px solid #eaedf3;
}
.navbar-search ul > li {
  list-style: none;
  padding: 0.375rem 0.5rem;
  cursor: pointer;
}
.navbar-search ul > li:hover {
  background-color: rgba(212, 175, 55, 0.12);
}

.navbar-res-view {
  position: absolute;
  background: #000;
  left: 0;
  right: 0;
  z-index: 5;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  /* animation: expand 1s; */
}
.navbar-res-view.open {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
}
.navbar-res-view ul {
  text-align: center;
  padding: 0;
  margin-top: 3rem;
}
.navbar-res-view ul li {
  list-style: none;
  line-height: 1.5;
}
.navbar-res-view ul li > a {
  color: #fff;
}
.navbar-res-view ul li:not(:last-child) {
  margin-bottom: 2rem;
}

.navbar-res-view ul li:last-child {
  padding: 0.5rem 0;
  border-radius: 40px;
  background: var(--primary);
}

/* how it works */

.bullet {
  height: 0.5rem;
  width: 0.5rem;
  min-width: 0.5rem;
}
.how-it-work-card .guide-text {
  font-size: 14px;
}
.show-more-slide {
  display: flex !important;
  width: auto !important;
  height: 317px;
}

@media (max-width: 768px) {
  .signup-selection img {
    height: 10rem;
  }
}

@media (min-width: 992px) {
  .col-lg-5c {
    flex: 0 0 20%;
    max-width: 100%;
  }
}

@media (max-width: 536px) {
  #landing-banner {
    height: 85vh;
  }
  #landing-banner .banner-content > p {
    padding: 0;
  }
  #landing-register-now p {
    margin-right: 0;
  }
  #focuses-section .focus-item img {
    height: 8.5rem;
    width: 8.5rem;
  }
}

/* Select User Type */
.signup-selection {
  border: 1px solid var(--primary);
  border-radius: 0.25rem;
  cursor: pointer;
  -webkit-transition: 0.7s;
  transition: 0.7s;
}
.signup-selection:hover {
  -webkit-transform: translateY(-0.25rem);
          transform: translateY(-0.25rem);
  box-shadow: 0 4px 8px 0 hsla(212, 83%, 7%, 0.11);
}

/* Skeleton */
.skeleton-section {
  height: 354px;
  overflow: hidden;
}
.banner-video {
  position: absolute;
  z-index: -99;
  background-color: #000;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
/* hide seek-bar */
.banner-video .overlay_1sxyvns,
.banner-video ._1sxyvns {
  display: none;
}
/* hide controller */
.banner-video .root_qwouf2,
.banner-video ._qwouf2 {
  display: none !important;
}

.gt-btn{
    /* font-size: 0.75rem; */
    font-weight: bold;
    /* line-height: 1; */
    padding: .625rem 1.5625rem;
    /* height: 3.425em; */
    color: #fff;
    border-radius: 3px;
    -webkit-transition: .2s;
    transition: .2s;
    white-space: nowrap;
    background-color: var(--primary)
}
.gt-btn:hover{
    color: #fff;
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
}
.gt-btn:active {
    background-color: var(--primary-active);
}
.gt-btn.large {
    font-size: 1.125rem;
    /* padding: 0.75rem 1.2rem; */
    font-weight: 600;
    /* height: unset; */
}
.outline {
    border: 1px solid #fff;
    background-color: transparent;
}
.gt-btn-sm {
    padding: 0.25rem 1rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0.15rem
}
.gt-btn-white {
    color: #212121;
    background-color: #fff;
    border-color: #d8d8d7;
}
.modal-title{
    font-size: 21px;
    font-weight: bold;
    line-height: 1.52;
    color: #212121;
}
.modal-header button:focus{
    outline: none !important
}
.publish-modal .modal-dialog {
    max-width: 73.75rem;
}
.feedback-modal .modal-dialog{
    max-width: 40.5rem;
}
textarea.gt-text-area-control:focus,
textarea.gt-text-area-control:hover{
    border-color: var(--primary);
    box-shadow: none;
    color: #031121;
}
textarea.gt-text-area-control:invalid {
    border-color: 'red'
}
textarea.gt-text-area-control.invalid {
    box-shadow: 0 0 14px 0 rgba(255, 0, 0, 0.2) !important;
    border-color: #ff0000 !important;
}
textarea.gt-text-area-control::-webkit-input-placeholder{
    color: #9c9c9c;
    font-size: 1rem;
}
textarea.gt-text-area-control::-moz-placeholder{
    color: #9c9c9c;
    font-size: 1rem;
}
textarea.gt-text-area-control::-ms-input-placeholder{
    color: #9c9c9c;
    font-size: 1rem;
}
textarea.gt-text-area-control::placeholder{
    color: #9c9c9c;
    font-size: 1rem;
}

/* Material */

textarea.gtm-text-area-control {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    color: #fff;
    padding: 0 0 .25rem 0;
    position: relative;
    box-sizing: border-box;
    resize: none;
}
textarea.gtm-text-area-control:focus{
    box-shadow: none;
    background: transparent;
    border-bottom: 2px solid #fff;
    color: #fff;
}
textarea.gtm-text-area-control::-webkit-input-placeholder { color: #ffffff98;}
textarea.gtm-text-area-control::-moz-placeholder { color: #ffffff98;}
textarea.gtm-text-area-control::-ms-input-placeholder { color: #ffffff98;}
textarea.gtm-text-area-control::placeholder { color: #ffffff98;}
textarea.gtm-text-area-control + .info-text {
    font-size: 0.75rem;
}
input.gt-form-control {
    font-size: 1rem;
    color: #212121;
    font-weight: normal;
    height: 3em;
    border-radius: 3px;
    background-color: #fff !important;
    border: solid 1px #dedede;
    padding: 0.375rem 0.75rem;
    box-shadow: none;
    outline: none;
}
.input-icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    z-index: 10;
    color: #9c9c9c;
    font-size: 1rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.input-icon + input.gt-form-control {
    padding-left: 2.25rem;
}
input.gt-form-control:focus,
input.gt-form-control:hover{
    border-color: var(--primary);
    color: #212121;
}
input.gt-form-control:invalid {
    border-color: 'red'
}
input[type='checkbox'] {
    -webkit-appearance: none;
    border: 1px solid #dedede;
    margin-right: 0.625em; 
    height: 1.1em;
    width: 1.1em;
	border-radius: 3px;
	display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 0;
}
input[type='checkbox']:focus {
    outline: none;
}
input[type='checkbox']:hover,
input[type='checkbox']:checked
{
    border-color:var(--primary);
    background-color: var(--primary);
}

input[type='checkbox']:checked::after {
    content: '';
    font-size: 1.1em;
    line-height: 1;
    position: absolute;
    display: inline-block;
    background-color: var(--primary);
    height: 100%;
    width: 100%;
    top: 0;
    background-image: url('/icons/tick-white.png');
    background-repeat: no-repeat;
    background-size: 0.625rem;
    background-position: center;
    /* display: flex;
    align-items: center;
    justify-content: center; */
	/* left: 2px; */
	color: var(--primary);
}
.gt-form-control:focus{
    color: #212121;
    box-shadow: none;
    border: solid 1px #dedede;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow:0 0 0 50px white inset; 
    -webkit-text-fill-color: #000; 
}
input.gt-form-control.invalid {
    box-shadow: 0 0 14px 0 rgba(255, 0, 0, 0.2) !important;
    border-color: #ff0000 !important;
}
.input-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 1em;
}
input.gt-form-control.material {
    background-color: transparent !important;
}
.gtm-form-control {
    background-color: transparent !important;
    border: none;
    border-bottom: 2px solid #fff !important;
    border-radius: 0;
    padding: 0.375em 0;
    text-align: left;
    color: #fff !important;
    position: relative;
    font-size: inherit;
}
.gt-primary{
    font-size: 1rem;
    color: #212121;
    font-weight: normal;
    height: 3em;
    border-radius: 3px;
    background-color: #fff !important;
    border: solid 1px #dedede !important;
    padding: 0.375rem 0.75rem;
    box-shadow: none !important;
    outline: none !important;
}
.gt-primary.invalid{
    box-shadow: 0 0 14px 0 rgba(255, 0, 0, 0.2) !important;
    border-color: #ff0000 !important;
}
.custom-select:focus {
    box-shadow: none;
    outline: none;
}
select > option {
    color: #212121;
    font-size: 1rem !important;
}
select.custom-select.gt-primary[datavalue=""] {
    color: #6c757c !important;
}
select.custom-select.gtm-form-control[datavalue=""] {
    color: #9c9f9e !important;
}
.feedback-modal h5{
    font-weight: bold;
}
.feedback-modal .icons{
    cursor: pointer;
}
.landing-footer {
    min-height: 16.5rem;
    background: #342f20;
    color: #fff;
}
#navigation-list{
    padding-top: 3.5rem;
}
#navigation-list li{
    list-style: none;
    font-weight: 600;
}
#navigation-list li a { color: #fff}

#navigation-list li:not(:last-child)::after {
    content: '\2022';
    margin: 0 1.875rem;
    color: #876d16;
    font-size: 1.3rem;
    line-height: 1;
}
.landing-footer p.footer-content {
    font-size: .75rem;
    margin: 2.375rem 0;
}

.landing-footer p.footer-content span {
    display: block;
}
.landing-footer .landing-footer-info { padding-bottom: 3.25rem; }

.landing-footer .landing-footer-info a {
    color: #fff;
    font-weight: 600
}

@media (max-width: 992px) { 
    #navigation-list li::after { 
        content: unset !important;
    };
}
.article-card {
    border-color: #eaedf3;
    cursor: pointer;
    -webkit-transition: .7s;
    transition: .7s;
    height: 334px;
    overflow: hidden;
}
.article-card h6.article-heading {
    margin-bottom: .5rem;
    color: #212121;
    font-weight: 600;
    line-height: 1.5;
     /* autoprefixer: off */
   -webkit-box-orient: vertical;
   display: block;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   overflow: hidden;
   text-overflow: ellipsis;
   word-break: break-word;
}
.article-card p.article-content {
    margin-bottom: .5rem;
    color: #9c9c9c;
    font-size: 0.75rem;
       /* autoprefixer: off */
   -webkit-box-orient: vertical;
   display: block;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   overflow: hidden;
   text-overflow: ellipsis
}

.article-card .article-image {
    height: 9rem;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.article-card .article-image > img {
    height: 100%;
    object-fit: cover;
    position: relative;
}

.article-card:hover{
    -webkit-transform: translateY(0);
            transform: translateY(0);
    /* box-shadow: 0 4px 8px 0 hsla(212, 83%, 7%, 0.11); */
}


.article-card:hover .article-image {
    background-image: -webkit-gradient(linear, left top, left bottom, from(hsl(212, 83%, 7%)), to(hsla(212, 83%, 7%, 0)));
    background-image: linear-gradient(to bottom, hsl(212, 83%, 7%), hsla(212, 83%, 7%, 0));
}


.article-card:hover .article-image > img {
    z-index: -10;
}

.article-card .article-footer {
    margin: 0;
    font-size: .75rem;
    line-height: 1.5;
    color: #031121;
}
.article-card .article-footer span:nth-child(2) {
    color: #9c9c9c;
}
.article-card .article-footer > span:nth-child(2)::before{
    content: '\2022';
    margin: 0 4px;
}
.article-card .type-icon{
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 100;
}

/* CONTENT CARD SKELTON */
.content-card-skeleton {
    border-color: #eaedf3;
    -webkit-transition: .7s;
    transition: .7s;
    height: 300px;
    overflow: hidden;
}
.img-placehoder {
    height: 9rem;
}
.img-placehoder.shine {
    background-size: 800px 9rem;
}
.practitioner-card {
    border-color: #eaedf3;
    height: 23.5rem;
    cursor: pointer;
    -webkit-transition: .7s;
    transition: .7s
}
.practitioner-card .card-body{
    padding: 1rem
}
.practitioner-card .rate-section{
    border-radius: 9px;
    border: solid 1px #b99458;
    font-size: .75rem;
    padding: 0 2px;
}
.practitioner-card:hover{
    /* transform: translateY(-0.5rem); */
    box-shadow: 0 4px 8px 0 hsla(212, 83%, 7%, 0.11);
}
.practitioner-card-content {
    /* height: calc(100% - 1.25rem); */
    flex: 1 1
}
.practitioner-card-content img {
    border-radius: 50%;
    height: 10rem;
    width: 10rem;
    margin-bottom: .5rem;
}
.practitioner-card-content h5 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #212121;
    line-height: 1.5
}
.practitioner-card-content p {
    line-height: 1.5;
    color: #9c9c9c;
    margin-bottom: .625rem;
    font-size: 0.75rem
}
.percentage-popover{
    background: #f4eee5;
    border: none;
    max-width: 15.81rem;
}
.percentage-popover p{
    margin-left: .75rem;
    color: #b99458;
}
.percentage-popover .arrow::after{
    border-right-color: #f4eee5
}
.percentage-popover .arrow::before{
 border-right-color: #ffffff
}
.practitioner-card .catagory-chips{
    border-radius: 3px;
    background-color: #eaedf3;
    cursor: pointer;
    color:#9c9c9c;
    font-size: 0.75rem
}
.practitioner-card-content p::before{
    content: url('/images/open-quote.png');
    position: relative;
    left: .5rem;
    top: .5rem;
}
/* .practitioner-card-content p::after{
    content:  url('/images/close-quote.png');
    position: absolute;
    margin-left: -1.125rem;
    bottom: 0;
} */
.practitioner-card .truncate{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.practitioner-card-content .user-image{
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
    object-fit: cover;
}
.practitioner-card .practitioner-card-footer{
    background-color: rgba(185, 148, 88, 0.21);
}
.practitioner-card .practitioner-card-footer p {
    color:  #b99458;
    line-height: 1.25;
}
.tags{
    background-color: #eaedf3;
    font-size: 12px;
    color: #9c9c9c;
    border-radius: 3px;
}


/* PRACTITIONER SKELETON */
.practitioner-skeleton-card {
    border-color: #eaedf3;
}
.slider-title {
    font-family: 'Playfair Display', serif;
}
@media (max-width: 768px) { 
    .slider-title {
        font-size: 1.5rem;
    }
}
.left-align-slick > .slick-slider > .slick-list > .slick-track {    
    margin-left:0;
}
.slick-prev.c-slick-prev,
.slick-next.c-slick-next {
    top:-40px;
}
.slick-prev.c-slick-prev::before,
.slick-next.c-slick-next::before {
    color: black;
    font-style: normal;
}
.slick-prev.c-slick-prev {
    right: 2.5rem;
    left: unset;
}
.slick-next.c-slick-next {
    right: 1rem;
}

.social-btn{
    color: #fff;
    font-size: .825rem;
    font-weight: bold;
    /* height: 3.5em; */
    padding: .625rem 0.75rem;
    border-radius: 3px;
}
.social-btn:hover {
    color: #fff;
}
.facebook {
    background-color: #4b72bb;
}
.google {
    background-color: #d8523d;
}
/* input.gt-form-control {
    font-size: 1rem;
    color: #9c9c9c !important;
    font-weight: normal;
    height: 3.5em;
    border-radius: 3px;
    background-color: #fff !important;
    border: solid 1px #dedede;
    box-shadow: none;
    outline: none;
}
.login-form input[type=text].gt-form-control,
.login-form input[type=password].gt-form-control{
    padding: 0.375rem 0.75rem
}
input.gt-form-control:focus,
input.gt-form-control:hover{
    border-color: var(--primary);
}
input.gt-form-control:invalid {
    border-color: 'red'
}
input[type='checkbox'] {
    -webkit-appearance: none;
    border: 1px solid #dedede;
    margin-right: 0.625em; 
    height: 1.1em;
    width: 1.1em;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}
input[type='checkbox']:focus {
    outline: none;
}
input[type='checkbox']:hover,
input[type='checkbox']:checked
{
    border-color:var(--primary);
}

input[type='checkbox']:checked::after {
    content: '\2714';
    font-size: 1.1em;
    line-height: 1;
	position: absolute;
	top: 0;
	left: 2px;
	color: var(--primary);
}
.gt-form-control:focus{
    box-shadow: none;
    border: solid 1px #dedede;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow:0 0 0 50px white inset; 
    -webkit-text-fill-color: #9c9c9c 
}
input.gt-form-control.invalid {
    box-shadow: 0 0 14px 0 rgba(255, 0, 0, 0.2) !important;
    border-color: #ff0000 !important;
}
.input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1em;
} */
.signin-banner {
    background-blend-mode: multiply;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#031121), to(rgba(3, 17, 33, 0))),url('/images/login_banner.png');
    background-image: linear-gradient(to top, #031121, rgba(3, 17, 33, 0)),url('/images/login_banner.png');
    background-size: cover;
    background-position: top;   
    color: #fff;               
}
.signin-banner h1 {
    font-size: 2.25rem;
    font-weight: bold;
}

.oauth-container::after {
    position: absolute;
    content: '';
    border-bottom: solid 1px #dedede;
    bottom: 0;
    right: 15%;
    left: 15%;
}
.oauth-container::before {
    position: absolute;
    content: 'OR';
    bottom: 0;
    z-index: 1;
    padding: 0 .5rem;
    color: #212121;
    font-size: .75rem;
    left: 50%;
    background-color: #fff;
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
}


.signin-text-box h2 {
    color: #212121;
    font-weight: bold;
    /* white-space: nowrap; */
}
.signin-text-box p {
    color: #9c9c9c;
}

.error-feedback {
    display: block;
    color: #ff0000;
    font-size: 0.75rem;
    line-height: 1.5;
    margin: 4px 0;
    /* position: absolute; */
    top: 1.5em;
    -webkit-animation: errorAnimate .3s;
            animation: errorAnimate .3s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1
}

@-webkit-keyframes errorAnimate {
    0%, 50% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }
    20%, 75% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes errorAnimate {
    0%, 50% {
        -webkit-transform: translateX(-5px);
                transform: translateX(-5px);
    }
    20%, 75% {
        -webkit-transform: translateX(5px);
                transform: translateX(5px);
    }
    100% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
.auth-footer {
    color: #212121;

}
.auth-footerlink {
    color: var(--primary);
    font-weight: 600;
}

.remember-me{
    color: #9c9c9c;
    font-size: .75rem;
}
.forgot-password {
    color: #212121;
    font-size: .75rem;
}
.forgot-password:hover {
    color: #212121;
}

@media (max-width: 768px) {
    .signin-text-box h2 {
        font-size: 1.75rem;
        white-space: nowrap;
    }
}

.react-datepicker-wrapper,
.react-datepicker__input-container
 {
    display: block !important;
}
div.react-datepicker__day--selected,
div.react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background-color: var(--primary);
}
div.react-datepicker__day:hover { border-radius: 50%}
.react-datepicker__year-select,
.react-datepicker__month-select {
    border-radius: 0.25rem;
    margin: 0 .25rem;
    cursor: pointer;
}
.react-datepicker__day:hover {
    background-color: #d4af375e !important;
}
.react-datepicker__year-select:focus,
.react-datepicker__month-select:focus{
    outline: none;
}

.gt-datepicker img[alt='datepicker'] {
    position: absolute;
    right: 0.75rem;
    top: 1rem;
    z-index: 1;
    height: 1rem;
    /* transform: translateY(-50%) */
}

.signup-banner{
    background-image: -webkit-gradient(linear, left bottom, left top, from(#031121), to(rgba(3, 17, 33, 0))), url('/images/signup.png');
    background-image: linear-gradient(to top, #031121, rgba(3, 17, 33, 0)), url('/images/signup.png');
    background-blend-mode: multiply;
    background-size: cover;
    background-position: center;
}

.logo-text {
    color: #031121;
    font-weight: bold;
}

.signup-text-box h4 {
    color: #212121;
    font-size: 1.75rem;
    font-weight: bold;
}
.signup-text-box p{
    /* font-size: 1.125rem; */
    color: #212121;
    font-weight: normal;
}
.checkbox-text{
    font-size: .75rem;
    color: #212121;
    font-weight: normal;
}
.checkbox-text-tc {
    /* text-decoration: none; */
    color: var(--primary);

}
.checkbox-text-tc:hover {
    color: var(--primary)
}
.signup-footer {
    position: absolute;
    bottom: 0;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
}

ul.gt-feature-list{
    padding: 0;
    margin: 0;
    margin-top: 5em;
}
ul.gt-feature-list li {
    list-style: none;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.9;
    color: #fff;
    margin-bottom: 1.905em;
    padding-left: 2em;
    text-transform: capitalize;
    position: relative;
}
ul.gt-feature-list li::before{
    position: absolute;
    content: '';
    display: inline-block;
    left:0;
    top: .45em;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background: #fff;
    background-image: url('/icons/tick.svg');
    background-repeat: no-repeat;
    background-size: .5em; 
    background-position: center; 
}

.auth-footer {
    color: #212121;

}
.auth-footerlink {
    color: var(--primary);
    font-weight: 600;
}
.auth-footerlink:hover {
    color: var(--primary);
}

.oauth-container::after {
    position: absolute;
    content: '';
    border-bottom: solid 1px #dedede;
    bottom: 0;
    right: 15%;
    left: 15%;
}
.oauth-container::before {
    position: absolute;
    content: 'OR';
    bottom: 0;
    z-index: 1;
    padding: 0 .5rem;
    color: #212121;
    font-size: .75rem;
    left: 50%;
    background-color: #fff;
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
}

#practitioner-signup-banner .guide {
    position: relative; 
    padding-left: 1.5rem;
    color: #fff;
 }
#practitioner-signup-banner .guide::before {
    content: '';
    height: .875em;
    width: .875em;
    border-radius: 50%;
    background-color: #fff;
    background-image: url('/icons/tick.svg');
    background-repeat: no-repeat;
    background-size: .5em; 
    background-position: center; 
    position: absolute;
    left: 0;
    top: .35em;
}
#practitioner-signup-banner .guide h5,
#practitioner-signup-banner .guide p
{
    font-size: 1rem;
    line-height: 1.5;
    margin: 0;
}
#practitioner-signup-banner .guide h5 { 
    font-weight: bold;
    margin-bottom: .25rem; 
}

.carousel-indicators li {
    border: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
}
.carousel-indicators li:nth-child(3) {
    width: 24px;
    border-radius: 4px;
}
.carousel-indicators .active {
    background-color: #d4af37;
}

.testimonial-text{
    font-weight: bold;
    text-align: center;
}

.testimony img[alt='testimony'] {
    height: 2.25rem;
    width: 2.5rem;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 1rem
}
#email-sent-view{
    height: 100vh;
    background-color: #f2f4f8;
}
.email-sent-card {
    background: #fff;
    min-height: 60vh;
    border: 1px solid var(--primary);
}
.email-sent-card img{
    width: 5rem;
}
.email-sent-card h3 {
    font-size: 1.75rem;
    font-weight: bold;
}
.email-sent-card p {
    margin: 0;
    color: #212121;
    text-align: center;
    padding: 0 25%;
}
.email-sent-card .mail {
    color: var(--primary);
    font-weight: 600;
    margin: 0 .25rem;
}
#assessment-welcome-page h2 {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.5;
}
#assessment-welcome-page h1 {
  font-weight: 900;
  font-size: 3.75rem;
  margin-bottom: 3.825rem;
  text-align: center;
}
#assessment-welcome-page .welcome-combined {
  display: block;
  -webkit-text-fill-color: transparent; /*Will override color (regardless of order)*/
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
}
#assessment-welcome-page .purpose {
  border-radius: 6px;
  border: solid 1px #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1rem;
}
#assessment-welcome-page .purpose p {
  margin: 0;
  /* text-align: left; */
}

.welcome-container {
  height: 100vh;
  overflow-y: auto;
}
.welcome-container::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}
@media (max-width: 536px) {
  #assessment-welcome-page h1 {
    font-size: 2.5rem;
    /* margin-bottom: 4.825rem; */
  }
}

.assesment-welcome-container {
    height: 100vh;
    background-blend-mode: multiply;
    background-color: #00000080;
    background-image: url('/images/assesment-blur.jpg');
    -webkit-transition: background-image .5s ease-in;
    transition: background-image .5s ease-in;
    background-size: cover;
    background-position: center; 
    color: #fff;
}
.question-list-item{
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 6.4em;
    opacity: 0.15;
    overflow: hidden;
}
.question-list-item.active {
    opacity: 1;
}

.assessment-footer{
    height: 5.25em;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.assessment-footer::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url('/images/Assessment_background.jpg');
    background-position: bottom;
    filter: blur(10px);
    -webkit-filter: blur(10px);
}
.nav-arrow-btn {
    background-color: #fff;
    padding: .35rem 1rem;
    line-height: 1;
    border: none;
    border-radius: 2px;
    /* width: 1.75em; */
}
.welcome-container {
    max-height: 100vh;
    overflow-y: auto;
}
.welcome-container::-webkit-scrollbar {
    display: none;
    width: 0 !important;
}
textarea.gt-text-area {
    background: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    color: #fff;
    padding: 0 55px 10px 0;
    position: relative;
    box-sizing: border-box;
    resize: none;
}
textarea.gt-text-area:focus{
    box-shadow: none;
    background: transparent;
    border-bottom: 2px solid #fff;
    color: #fff;
}

.text-area-ok {
    border: none;
    border-radius: 2px;
    background: #fff;
    position: absolute;
    right: 15px;
    bottom: 10px;
    font-size: 0.75rem;
    padding-right: 1.25rem;
    /* position: relative; */
}
.text-area-ok::after {
    position: absolute;
    content: '';
    z-index: 5;
    right: 5px;
    height: 1em;
    width: 1em;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-image: url('/icons/tick-black.png');
    background-repeat: no-repeat;
    background-size: .5rem; 
    background-position: center;
}
textarea.gt-text-area::-webkit-input-placeholder {
    color: #ffffff98;
}
textarea.gt-text-area::-moz-placeholder {
    color: #ffffff98;
}
textarea.gt-text-area::-ms-input-placeholder {
    color: #ffffff98;
}
textarea.gt-text-area::placeholder {
    color: #ffffff98;
}

form.assessment-form::-webkit-scrollbar{
    display: none !important;
    width: 0 !important;
    -ms-overflow-style: none !important;
}
form.assessment-form .question-list-item:last-child {
    margin-bottom: 65vh;
}
form.assessment-form{
    overflow: auto;
    max-height: calc(100vh - 5.25rem);
    padding-top: calc(50vh - 5.25em);
    scrollbar-width: none !important;
}

/* HELP LINE MODAL STYLES */
#helpline-modal .helpline {
    padding-left: 2.25rem;
}

#helpline-modal .state-title {
    color: #212121;
    font-size: 1.25rem;
    font-weight: bold
}
#helpline-modal .get-help-text {
    color: #212121;
    font-size: 1.5rem;
    font-weight: bold
}

#helpline-modal .helpline span {
    font-size: 0.875rem;
}
#helpline-modal .helpline span:first-child {
    color: #212121;
    font-weight: 600;
}
#helpline-modal .helpline::before {
    content: '\2022';
    font-size: 1.5rem;
    line-height: 1;
    color: var(--primary);
    position: absolute;
    left: 1rem;
}
#helpline-modal .helpline span:nth-child(2) {
    color: #9c9c9c;
    margin-left: 0.5rem;
}
.btn-scale {
    border: 1px solid #fff;
    border-radius: 6px;
    -webkit-transition: .3s;
    transition: .3s;
}
.btn-scale {
    color: #fff;
    background-color: rgba(255, 255, 255, 0);
    height: 3.75em;
    width: 3.75em;
}
.btn-scale:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.25);
}
.btn-scale.active {
    color: #031121;
    background-color: rgba(255, 255, 255, 1);
    /* animation: flicker 0.25s ease 0s 2 normal none running; */
}
.additinal-info-field { width: 650px; }

/* .range-group::before {
    content: 'Not at all';
    position: absolute;
    font-size: 12px;
    font-weight: 400;
    left: 0;
    bottom:-1.5em;
}
.range-group::after {
    content: 'Extremely';
    position: absolute;
    font-size: 12px;
    font-weight: 400;
    right: 0;
    bottom:-1.5em;;
} */

.range-group .btn-scale::before{
    content: '';
    position: absolute;
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    left: 0;
    bottom:-1.75em;
}

.range-group .btn-scale:not(.often):first-child::before { content: 'Not at all'; }
.range-group .btn-scale:not(.often):last-child::before { content: 'Extremely'; right: 0; text-align: right }

.range-group .btn-scale.often:nth-child(1)::before{content: 'Never'}
.range-group .btn-scale.often:nth-child(3)::before{content: 'Rarely'}
.range-group .btn-scale.often:nth-child(5)::before{content: 'Sometimes'}
.range-group .btn-scale.often:nth-child(7)::before{content: 'Regulary'}
.range-group .btn-scale.often:nth-child(9)::before{content: 'Often'}
.range-group .btn-scale.often:nth-child(11)::before{content: 'Frequently'; right: 0;}


/* RADIO BUTTON STYLES */

.radio-option label {
    position: relative;
    border-radius: 6px;
    font-size: .75rem;
    border: solid 1px #979797;
    cursor: pointer;
    padding: 0.625em 3.75em 0.625em 3em;
    min-width: 14em;
    text-transform: capitalize; 
}
.radio-option label:hover {
    background-color: rgba(255, 255, 255, 0.14);
}
.radio-option label::before {
    content: 'A';
    position: absolute;
    height: 1.65em;
    width: 1.65em;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #031121;
    top: 50%;
    left: 0.625em;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%)
}
.radio-option input:checked +  label::after{
    content: '';
    position: absolute;
    height: 1.65em;
    width: 1.65em;
    background-image: url('/icons/tick-white.png');
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    right: 0.625em;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    /* animation: flicker 0.25s ease 0s 2 normal none running; */
}
.radio-option:nth-child(1) label::before { content: 'A' }
.radio-option:nth-child(2) label::before { content: 'B' }
.radio-option:nth-child(3) label::before { content: 'C' }
.radio-option:nth-child(4) label::before { content: 'D' }
.radio-option:nth-child(5) label::before { content: 'E' }
.radio-option:nth-child(6) label::before { content: 'F' }

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
input:-webkit-autofiill:focus-within,
input:-webkit-autofill:active {
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-transition: background-color 5000s ease-in-out 0s !important;
    transition: background-color 5000s ease-in-out 0s !important;
}

.gtm-invalid-feedback{
    width: 100%;
    border-radius: 2px;
    background-color: hsla(0, 0%, 100%, 0.6);
    color: #ff0000;
    font-size: 60%;
    padding: 0 0.25rem;
    position: relative;
    -webkit-animation: errorAnimate 0.3s;
            animation: errorAnimate 0.3s;
}
.gtm-invalid-feedback::after {
    content: '';
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: #ff0000;
    right: 0.5rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%)
}

@-webkit-keyframes flicker {
    50% { opacity: 0.1;}
}

@keyframes flicker {
    50% { opacity: 0.1;}
}

@media (max-width: 760px) {
    .btn-scale {
        height: unset;
        width: unset;
        padding: 0.35rem 0;
        font-size: 12px;
    }
    .range-group { width: 100% }

    .range-group::before {
        content: 'Not at all';
        position: absolute;
        font-size: 12px;
        font-weight: 400;
        left: 0;
        bottom:-1.5em;
    }
    .range-group::after {
        content: 'Extremely';
        position: absolute;
        font-size: 12px;
        font-weight: 400;
        right: 0;
        bottom:-1.5em;
    }

    .range-group .btn-scale::before{content: unset !important}
    .range-group.often-group::before { content: 'Never'; }
    .range-group.often-group::after { content: 'Frequently'; }

    .radio-option label {
        min-width: 11em;
    }

    .question-list-item {
        font-size: 1rem;
        margin-bottom: 4.4em;
    }
    .additinal-info-field { width: unset; }
}
.search-multi-dropdown-control {
    background: none;
    border: none;
    font-size: 1.25rem;
    border-radius: 0;
    color: #fff;
    border-bottom: 2px solid #fff;
    padding: .15rem 0;
    padding-top: 0;
    height: unset; 
}
.gt-form-control-primary {
    font-size: 1rem;
    color: #9c9c9c;
    font-weight: normal;
    border-radius: 3px;
    background-color: #fff !important;
    border: solid 1px #dedede;
    padding: 0.625rem 0.75rem;
    box-shadow: none;
    outline: none;
}

.gt-form-control-primary .search-multi-dropdown-input {
    color: #212121;
}
.gt-form-control-primary .search-multi-dropdown-input:focus {
    color: #212121;
}
.gt-form-control-primary .search-multi-dropdown-input::-webkit-input-placeholder { color: #9c9c9c; }
.gt-form-control-primary .search-multi-dropdown-input::-moz-placeholder { color: #9c9c9c; }
.gt-form-control-primary .search-multi-dropdown-input::-ms-input-placeholder { color: #9c9c9c; }
.gt-form-control-primary .search-multi-dropdown-input::placeholder { color: #9c9c9c; }


.search-multi-dropdown-input{
    background: none;
    border: none;
    border-radius: 0;
    color: #fff;
    min-width: 1rem;
    padding: 0;
}

.search-multi-dropdown-input:focus {
    color: #fff;
    background: transparent;
}
.search-multi-dropdown-input::-webkit-input-placeholder { color: #ffffff98; }
.search-multi-dropdown-input::-moz-placeholder { color: #ffffff98; }
.search-multi-dropdown-input::-ms-input-placeholder { color: #ffffff98; }
.search-multi-dropdown-input::placeholder { color: #ffffff98; }


.search-multi-dropdown-control .selected-item {
    background: #fff;
    color: #212121;
    padding: 0.25rem 0.825rem;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    margin-right: .5rem;
    margin-bottom: .35rem;
    text-transform: capitalize;
}
.search-multi-dropdown-control .selected-item img{
    height: .625rem;
    margin-left: .25rem;
    margin-bottom: .1rem;
    cursor: pointer;
}

.gt-form-control-primary .selected-item {
    background: #eaedf3;
}


ul.search-multi-dropdown-box{
    position: absolute;
    right: 0;
    left: 0;
    margin-top: 0.15rem;
    margin-bottom: .5rem;
    background: #fff;
    color: #212121;
    max-height: 30vh;
    overflow: auto;
    border: 1px solid #dbdbdb;
    z-index: 100;
}
ul.search-multi-dropdown-box {
    padding: 0;
}
ul.search-multi-dropdown-box li {
    list-style: none;
    padding: 0.325rem .75rem;
    font-size: 1rem;
    cursor: pointer;
    text-transform: capitalize;
}
ul.search-multi-dropdown-box li:hover{
    background-color: hsla(46, 65%, 52%, 0.12);
}
ul.search-multi-dropdown-box li.active{
    font-weight: 600;
    color: #fff;
    background-color: var(--primary);

}
#progresscheck-welcome-page h2 {
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.5;
}
#progresscheck-welcome-page h1 {
  font-weight: 900;
  font-size: 3.75rem;
  margin-bottom: 6.825rem;
  text-align: center;
}
#progresscheck-welcome-page .welcome-combined {
  display: block;
  -webkit-text-fill-color: transparent; /*Will override color (regardless of order)*/
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fff;
}
#progresscheck-welcome-page .purpose {
  border-radius: 6px;
  border: solid 1px #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1rem;
}
#progresscheck-welcome-page .purpose p {
  margin: 0;
  text-align: left;
}

.welcome-container {
  height: 100vh;
  overflow-y: auto;
}
.welcome-container::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}
@media (max-width: 536px) {
  #progresscheck-welcome-page h1 {
    font-size: 2.2rem;
    margin-bottom: 4.825rem;
  }
  #progresscheck-content h3 {
    font-size: 1.4rem;
  }
}

.question-list-item {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 6.4em;
  opacity: 0.15;
}
.question-list-item.active {
  opacity: 1;
}

.face-emoji {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.face-emoji-active {
  opacity: 1;
  -webkit-animation: pop 0.3s ease;
  animation: pop 0.3s ease;
}

@-webkit-keyframes pop {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pop {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.face-emoji-inactive {
  opacity: 0.2;
}

form.progress-check-in-form::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  -ms-overflow-style: none !important;
}
form.progress-check-in-form .question-list-item:last-child {
  margin-bottom: 65vh;
}
form.progress-check-in-form {
  overflow: auto;
  max-height: calc(100vh - 5.25rem);
  padding-top: calc(50vh - 5.25em);
  scrollbar-width: none !important;
}

.face-dimension {
  width: 45px;
  height: 45px;
}

.face-container {
  border-radius: 6px;
  border: solid 1px #ffffff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.face-content {
  font-size: 0.8rem;
}

.tick {
  position: absolute;
  top: -1px;
  right: -2px;
  -webkit-animation: slideTick 0.3s ease;
          animation: slideTick 0.3s ease;
}

@-webkit-keyframes slideTick {
  0% {
    top: -25px;
    right: -25px;
  }
  100% {
    top: -1px;
    right: -2px;
  }
}

@keyframes slideTick {
  0% {
    top: -25px;
    right: -25px;
  }
  100% {
    top: -1px;
    right: -2px;
  }
}

.assesment-welcome-container {
  height: 100vh;
  background-blend-mode: multiply;
  background-color: #00000080;
  background-image: url("/images/assesment-blur.jpg");
  -webkit-transition: background-image 0.5s ease-in;
  transition: background-image 0.5s ease-in;
  background-size: cover;
  background-position: center;
  color: #fff;
}
.question-list-item {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 6.4em;
  opacity: 0.15;
}
.question-list-item.active {
  opacity: 1;
}

.assessment-footer {
  height: 5.25em;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}
.assessment-footer::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url("/images/Assessment_background.jpg");
  background-position: bottom;
  filter: blur(13px);
  -webkit-filter: blur(10px);
}
.progress-check-in-footer.assessment-footer::before {
  background-image: url("/images/progressCheck.jpg");
  filter: blur(9px);
  -webkit-filter: blur(9px);
}
.nav-arrow-btn {
  background-color: #fff;
  padding: 0.35rem 1rem;
  line-height: 1;
  border: none;
  border-radius: 2px;
  /* width: 1.75em; */
}
.welcome-container {
  max-height: 100vh;
  overflow-y: auto;
}
.welcome-container::-webkit-scrollbar {
  display: none;
  width: 0 !important;
}

.success {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-animation: 0.8s successPop ease;
          animation: 0.8s successPop ease;
}

@-webkit-keyframes successPop {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes successPop {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.load-bar {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #d4af37;
}
.bar {
    content: "";
    /* display: inline; */
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
}
  .bar:nth-child(1) {
    background-color: #d4af37;
    -webkit-animation: loading 3s linear infinite;
    animation: loading 3s linear infinite;
  }
  .bar:nth-child(2) {
    background-color: #fff;
    -webkit-animation: loading 3s linear 1s infinite;
            animation: loading 3s linear 1s infinite;
  }
  .bar:nth-child(3) {
    background-color: #d4af37;
    -webkit-animation: loading 3s linear 2s infinite;
            animation: loading 3s linear 2s infinite;
  }
  
  @-webkit-keyframes loading {
      from {left: 50%; width: 0;z-index:100;}
      33.3333% {left: 0; width: 100%;z-index: 100;}
      to {left: 0; width: 100%;}
  }
  /* @keyframes loading {
      from {width: 0;z-index:100;}
      /* 33.3333% {width: 100%;z-index: 10;} */
      /* to {left: 0; width: 100%;} */
  /* }  */
.gtm-input {
    background: transparent;
    border: none;
    border-bottom: 2px solid #fff;
    border-radius: 0;
    box-shadow: none;
    font-size: unset;
    padding: 0;
    height: unset;
    padding-bottom: .5rem;
    color: #fff;
}
.gtm-input:focus{
    color: #fff;
    background: transparent;
    box-shadow: none;
    border-bottom-color: var(--primary)
}

.gtm-input::-webkit-input-placeholder, .gtm-form-control::-webkit-input-placeholder
{
    color: #ffffff98 !important;
}

.gtm-input::-moz-placeholder, .gtm-form-control::-moz-placeholder
{
    color: #ffffff98 !important;
}

.gtm-input::-ms-input-placeholder, .gtm-form-control::-ms-input-placeholder
{
    color: #ffffff98 !important;
}

.gtm-input::placeholder,
.gtm-form-control::placeholder
{
    color: #ffffff98 !important;
}
.autocomplete-dropdown-container {
    background-color: #ffffff;
    border: 1px solid #dedede;
    color: #222222;
}
.suggestion-item {
    padding: 0.5rem 0.625rem;
}
.suggestion-item-active {
    padding: 0.5rem 0.625rem;
    background-color: #fafafa;
}
.gtm-form-control + .autocomplete-dropdown-container {
    border: 0;
}
.gt-radio-option label,
.gtm-radio-option label {
    position: relative;
    border-radius: 6px;
    font-size: 1rem;
    border: solid 1px #979797;
    cursor: pointer;
    padding: 0.625rem 1em;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    -webkit-transition: .2s;
    transition: .2s; 
}

.gtm-radio-option label:hover {
    background-color: #fff8ec;
    border-color: #d4af37
}
.gt-radio-option label:hover {
    background-color: rgba(255, 255, 255, 0.2);
}
.gtm-radio-option label.selected,
.gtm-radio-option input:checked + label{
    background-color: #fff8ec;
    border-color: #d4af37 
}


.gt-radio-option label.selected,
.gt-radio-option input:checked + label { 
    background-color: #fff;
    color: #031121;
}
.gtm-radio-option label.selected::after,
.gtm-radio-option input:checked +  label::after,
.gt-radio-option label.selected::after,
.gt-radio-option input:checked +  label::after{
    content: '';
    height: 1em;
    width: 1em;
    background-image: url('/icons/tick-black.png');
    background-repeat: no-repeat;
    background-position: center;
    margin-left: .5em;
}
label.selected .other-text-field { color: #031121; }
.other-text-field { 
    color: #031121;
    background: transparent !important;
    border: none;
    color: #fff;
}
.search-dropdown-input {
    background: none;
    border: none;
    font-size: 1.25rem;
    border-radius: 0;
    color: #fff;
    border-bottom: 2px solid #fff;
    height: unset;
    padding: 0;
    padding-bottom: 0.5rem;
}

.search-dropdown-input:focus {
    color: #fff;
    background: transparent;
    border-bottom: 2px solid #fff;

}
.search-dropdown-input::-webkit-input-placeholder { color: #ffffff98; }
.search-dropdown-input::-moz-placeholder { color: #ffffff98; }
.search-dropdown-input::-ms-input-placeholder { color: #ffffff98; }
.search-dropdown-input::placeholder { color: #ffffff98; }

.search-dropdown-box{
    position: absolute;
    right: 0;
    left: 0;
    background: #fff;
    border: 1px solid #dbdbdb;
    color: #212121;
    max-height: 30vh;
    overflow: auto;
    margin: .25rem 0;
    z-index: 1500;
    padding: 0;
}
/* .search-dropdown-box ul {
    margin: 0;
    padding: 0;
} */
.search-dropdown-box li {
    list-style: none;
    padding: 0.325rem .75rem;
    font-size: 1rem;
    cursor: pointer;
    text-transform: capitalize;
}
.search-dropdown-box li:hover{
    background-color: hsla(46, 65%, 52%, 0.12);
}
.search-dropdown-box li.active{
    font-weight: 600;
    color: #fff;
    background-color: var(--primary);
}
#practitioner-registration-container {
    height: 100vh;
    background-blend-mode: multiply;
    background-color: #00000080;
    background-image: url('/images/Practitioner_background.jpg');
    -webkit-transition: background-image .5s ease-in;
    transition: background-image .5s ease-in;
    background-size: cover;
    background-position: center; 
    color: #fff;
}

.practitioner-create-profile {
    height: calc(100vh - 5.25rem);
    overflow-y: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
}
.practitioner-create-profile::-webkit-scrollbar { width: 0 !important }

.pract-reg-header { margin-bottom: 2rem}
.pract-reg-header h3 {
    font-size: 2.25rem;
    font-weight: bold;
    margin-bottom: .25rem; 
}

.pract-reg-header p {
    font-size: 1rem;
    margin: 0;
}

#practitioner-registration-container .prac-reg-label {
    margin-bottom: 0;
}

.tell-us-more h2 {
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
}
.tell-us-more p { 
    text-decoration: underline;
    font-weight: 600;
    margin: 0;
    font-size: 1rem;
    cursor: pointer;
}

.profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #dedede;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

/* PRACTITIONER REG COMPLETE */
#pract-reg-complete h2 {
    line-height: 1.18;
    font-weight: bold;
    margin-bottom: 3rem;
}

#pract-reg-complete h4 {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.18;
    margin-bottom: .5rem;
}

#pract-reg-complete p {
    padding: 0 30%;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}

#pract-reg-complete .links > a:first-child {
    text-decoration: none;
    padding: .625rem 1.25rem;
    background: #fff;
    color: #212121;
    border-radius: 6px;
    /* margin-right: 1.875rem; */
    font-size: 1rem;
}
#pract-reg-complete .links > a:nth-child(2){ 
    color: #fff;
    font-size: 1rem;
}


.certificate-selected {
    border: 1px solid #fff;
    border-radius: 6px;
    padding: .5rem .75rem;
    position: relative;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.certificate-selected div {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%,-50%);
            transform: translate(50%,-50%);
    height: 1rem;
    width: 1rem;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.certificate-selected div svg {
    width: .5rem;
    height: .5rem;
}

.certificate-selected p:nth-child(2) {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}
.certificate-selected p:nth-child(3){
    margin: 0;
    font-size: 0.75rem;
}

/* TIME AVAILABILTY */

#time-availability .day {
    margin-bottom: 1.75rem;
}

#time-availability .day label {
    position: relative;
    border-radius: 6px;
    font-size: 1rem;
    border: solid 1px #979797;
    cursor: pointer;
    padding: 0.625rem 1.25em;
    min-width: 6.25em;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    -webkit-transition: .2s;
    transition: .2s; 
}
#time-availability .day-choosed {
    position: absolute;
    width: 100%;
    top: -2rem;
    font-size: 0.625rem;
    font-weight: 600;
    padding: 0.25rem 0.325rem;
    text-align: center;
    border: 1px solid #fff;
    background: #fff;
    color: #212121;
    border-radius: .325rem; 
}

#time-availability .day-choosed::after {
    content: '';
    height: 8px;
    width: 8px;
    background: #fff;
    position: absolute;
    right: 50%;
    bottom: 0;
    -webkit-transform: translate(50%,50%) rotate(45deg) ;
            transform: translate(50%,50%) rotate(45deg) ;
}

#time-availability .day label:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

#time-availability .day input:checked + label { 
    background-color: #fff;
    color: #031121;
}

#time-availability .day input:checked +  label::after{
    content: '';
    height: 1em;
    width: 1em;
    background-image: url('/icons/tick-black.png');
    background-repeat: no-repeat;
    background-position: center;
    margin-left: .5em;
}
#time-availability .title-message {
    font-size: 16px;
}
#time-availability .title-message > span {
    font-weight: bold;
    margin: 0 .25rem;
}

#time-availability .title-message > span:not(:last-child)::after {
    content: ',';
}

.slider-container {
    padding: 1rem;
    border: 1px solid #fff;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.2);
    position: relative;
}

#time-availability .slider-container::before {
    border: 0.75rem solid #fff;
    border-color: transparent;
    border-bottom-color: #fff;
    position: absolute;
    right: 50%;
    top: -1.5rem;
    z-index: 10;
}


/* PRACTITIONER REG FOOTER */

.prac-reg-footer{
    height: 5.25em;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.prac-reg-footer::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: url('/images/Practitioner_background.jpg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100%;
    filter: blur(13px);
    -webkit-filter: blur(13px);
}

/*TIME AVAILABILITY - varient-primary */
#time-availability.primary .day input:checked + label { 
    background-color: #fff8ec;
    border-color: var(--primary);
    color: #212121;
}
#time-availability.primary .day label:hover {
    background-color: #fff8ec;
}
#time-availability.primary .day-choosed{
    position: absolute;
    width: 100%;
    top: -2rem;
    font-size: 0.625rem;
    font-weight: 600;
    padding: 0.25rem 0.325rem;
    text-align: center;
    border: 1px solid #fff;
    background: #031121;
    color: #fff;
    border-radius: .325rem; 
}
#time-availability.primary .day-choosed::after {
    content: '';
    height: 8px;
    width: 8px;
    background: #031121;
    position: absolute;
    right: 50%;
    bottom: 0;
    -webkit-transform: translate(50%,50%) rotate(45deg) ;
            transform: translate(50%,50%) rotate(45deg) ;
}

#time-availability.primary .slider-container {
    border-color: var(--primary);
}
@media (max-width: 536px) {
    .cert-del-btn {
        border-bottom: 1px solid #dedede;
        margin-bottom: 2rem;
    }
}
input[type='checkbox'].switch-checkbox {display: none;}
.switch-label { 
    color: #fff;
    font-size: 1.25rem;
    font-weight: bold;
    /* margin-right: 4rem; */
    position: relative;
}
label.switch {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 1.125rem;
    border: 1px solid #d8d8d8;
    border-radius: 40px;
    margin: 0;
    background-color: #ffffff33;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
label.switch::before {
    content: '';
    display: inline-block;
    position: absolute;
    height: 1.125rem;
    width: 1.125rem;
    border-radius: 50%;
    background: #fff;
    top: -1px;
    left: -1px;
    z-index: 1;
    -webkit-transition: 0.3s ease-in;
    transition: 0.3s ease-in;
    justify-content: center;
}
label.dark-switch {
    background-color: #d8d8d8;
    width: 2.5rem;
    height: 1.5rem;
}
label.dark-switch::before { 
    background: #fff;
    width: 1.3rem;
    height: 1.3rem;
    top: 0;
    left: 0;
}
input[type='checkbox'].switch-checkbox:checked + label.switch::before {
    -webkit-transform: translateX(0.95rem);
            transform: translateX(0.95rem);
}
input[type='checkbox'].switch-checkbox:checked + label.dark-switch::before {
    -webkit-transform: translateX(1rem);
            transform: translateX(1rem);
}
input[type='checkbox'].switch-checkbox:checked + label.switch{
    border-color: var(--primary);
    background-color: var(--primary); 
}
.input-range__track {
    background: rgba(255,255,255,0.2);
    height: 0.75rem;
}
.input-range__track--active{ background: #fff}

.input-range__label--value {top: -3rem;}

.input-range__label--min .input-range__label-container,
.input-range__label--max .input-range__label-container 
{ left: 0;}

.input-range__label--value .input-range__label-container {
    border: 1px solid #fff;
    border-radius: 5px;
    padding: .125rem .5rem;
    background: #fff;
    color: #212121;
    font-weight: 600;
    position: relative;
}
.input-range__label--value .input-range__label-container::after {
    content: '';
    height: 8px;
    width: 8px;
    background: #fff;
    position: absolute;
    right: 50%;
    bottom: 0;
    -webkit-transform: translate(50%,50%) rotate(45deg) ;
            transform: translate(50%,50%) rotate(45deg) ;
}
.input-range__label {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
}
.input-range__slider { 
    background: #fff;
    border: solid 1px rgba(151, 151, 151, 0.3);
    height: 1.25rem;
    width: 1.25rem;
    margin-top: -1rem; 
}

.primary .input-range__track {
    background: rgba(151, 151, 151, 0.3);
}
.primary .input-range__track--active { 
    background: var(--primary); 
}
.primary .input-range__slider{
    border: solid 2px var(--primary);
}
.primary .input-range__label {
    color: #221122;
}
.primary .input-range__label--value .input-range__label-container{
    background: #000;
    color: #fff;
    font-weight: normal;
}
.primary .input-range__label--value .input-range__label-container::after {
    background: #000;
}

/* .navbar{
   height: 3.875rem !important;
   border-bottom: 1px solid #eaedf3;
} */
.navbar .triangle{
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-top: 4px solid  #031121;
    border-radius:0.3px; 
}
.navbar .publish{
    font-family: Source Sans Pro;
    font-size:  0.875rem;
    font-weight: bold;
    color: #031121;
}
.navbar img{
    cursor: pointer;
}
.navbar .searchBox{
    border: 1px solid #eaedf3;
    border-radius:3px; 
    font-size:0.875rem
}
.navbar .searchBox svg{
    position: absolute;
    height: 1rem;
    right: 1rem;
    top: 30%;
    z-index: 3;
}
/* .searchBox input{
    padding: 0 5.875rem; 
} */
.line{
    width: 2px;
    background-color: #eaedf3;
}

.publish-modal .modal-dialog {
    max-width: 73.75rem;
}

.navbar-shared .nav-link{
    font-size: 1rem;
    line-height: 1.5;
    color: #9c9c9c;
}
.navbar-shared .nav-link:hover{
    color: #9c9c9c !important;

}
.navbar .flags{
    border-right: 1px solid #eaedf3;
    border-left: 1px solid #eaedf3;
}
.btn-signup{
    border-radius: 3px;
    background-color: #d4af37;
    color:#fff;
    font-weight: bold;
}
.btn-signup:hover{
    color:#fff !important;
    
}
.btn-signup a{
    color:#fff !important;
    text-decoration: none !important
}
.searchBoxBorder{
    border-bottom: 1px solid #eaedf3;
    padding-bottom:1rem;
}
.navbar-shared{
    /* padding: 0  8rem 0 8rem; */
    /* height: 3.875rem !important; */
    padding: 0.625rem 0;
    border-bottom: 1px solid #eaedf3;
    background: #fff
}
/* .navbar-shared svg{
    cursor: pointer;
    fill: #BFBFBF
}  */
/* .navbar-shared svg:hover{
    fill: #d4af37;
} */
.navbar-flag-1:hover,
.navbar-flag-2:hover,
.navbar-flag-3:hover{
    fill: #d4af37;
    border-radius: 16px;
    background-color: #f6ebc9;
   
}
.navbar-flag-1{
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
 /* .popover-body{
    padding: 0rem;
} */

#navbar-user-popover {
    min-width: 12rem;
    top: 10px !important;
}
.popover-links a,
.popover-links div{
    color: #9c9c9c;
    font-size: 0.75rem;
    padding:0.375rem 0.75rem;
    cursor: pointer;
}

.popover-links a:hover,
.popover-links div:hover{
    color: black;
    text-decoration: none;
    background-color:#FAF3E5;
   
}
/* .navbar-flag-2{
    padding-left: 0.5rem;
    padding-right: 0.4rem;   
}
.navbar-flag-3{
    padding-left: 0.5rem;
    padding-right: 0.4rem;  
} */
/* .bs-popover-bottom  .arrow::before,
.bs-popover-bottom  .arrow::after ,
.bs-popover-auto[x-placement^="bottom"]  .arrow::before,
.bs-popover-auto[x-placement^="bottom"]  .arrow::after{
    left:30px
} */

.navbar-profile-dropdown {
    position: absolute;
    background: #FFF;
    border-radius: 6px;
    left: 0;
    right: 0;
    z-index: 200;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    /* animation: expand 1s; */
}
.navbar-profile-dropdown.open { -webkit-transform: scaleY(1); transform: scaleY(1) }
.navbar-profile-dropdown ul {
    text-align: center;
    padding: 0;
    margin-top: 1.5rem;
}
.navbar-profile-dropdown ul li {
    list-style: none;
    line-height: 1.5;
}
.navbar-profile-dropdown ul li > a,
.navbar-profile-dropdown ul li > div
{color: #9c9c9c}

.navbar-profile-dropdown ul li:not(:last-child){
    margin-bottom: 1rem;
}
.message-indicator{
    display: inline-block;
    height: 0.375rem;
    width: 0.375rem;
    background-color: #4392f1;
    position: absolute;
    top: 0;
    right: 1.6rem;
    border-radius: 50%
}

@media (max-width: 1146px) {
    .navbar{
        height: auto !important;
    }
    .customer-logo{
        padding-bottom: 0.5rem !important
    }
} 
@media (max-width:992px){
    .logged-bar-nav{ width: 100%;}
    .navbar .flags{
        border:none
     }
    .navbar-shared .popover{
        display: none;
     }
}
 @media(min-width:992px){
    .searchBoxBorder{
        border-bottom: 0;
    }
 }

@media (max-width: 536px) {
 .searchBox  .navbar-search {
        -webkit-transform: scaleX(1);
                transform: scaleX(1)
    }
}
.selection-card{
    border-radius: 10px;
    border: solid 1px #d4af37;
    cursor: pointer;
    -webkit-transition: .7s;
    transition: .7s

}
.selection-card .type{
    font-size: 21px;
    font-weight: bold;
    line-height: 1.52;
    color: #212121
}
.selection-card span{
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #9c9c9c;
}
.selection-card:hover{
    -webkit-transform: translateY(-0.5rem);
            transform: translateY(-0.5rem);
    box-shadow: 0 5px 24px 0 hsla(212, 83%, 7%, 0.27);
}
.modal-body  .head{
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    color: #212121;
}
 .modal-body .description{
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #9c9c9c;
  }
.modal-body .footer p{
    font-size: 0.75rem;
    line-height: 2;
    color: #9c9c9c;
}
.tooltip-inner{
    max-width:308px !important;
}
.content-form h6{
    font-size: 0.75rem;
    font-weight: bold;
    color: #9c9c9c;
}
.content-form input::-webkit-input-placeholder{
    font-size: 1rem;
    /* color: #212121;; */
}
.content-form input::-moz-placeholder{
    font-size: 1rem;
    /* color: #212121;; */
}
.content-form input::-ms-input-placeholder{
    font-size: 1rem;
    /* color: #212121;; */
}
.content-form input::placeholder{
    font-size: 1rem;
    /* color: #212121;; */
}
.content-form textarea.form-control{
    height: 11.437rem;
}

.modal-body .btn-cancel{
    color: #9c9c9c;
}


.dragzone h5{
    opacity: 0.8;
    font-size: 21px;
    font-weight: bold;
}
.dragzone p{
    opacity: 0.8;
    font-size: 12px;
    color: #9c9c9c;
}

.dragzone:focus{
    outline: none
}

#bgvid {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    object-fit: cover; /* cover works perfectly on Safari */
}
  
.publish-vedio-wrapper {
    width: 100%;
    min-width: 100%;
    height: 364px;
    max-height: 364px;
    overflow: hidden;
}

.publish-content-form-wrapper .progress{
    height: 10rem;
    background-color: #fff9e5;
}
.publish-content-form-wrapper .progress-bar {
    background-color:  #fff0c0;
    color: #212121;
    font-size: 1.25rem;
    font-weight: bold;
}
.publish-content-form-wrapper .progress-bar-striped {
    background-size: 2.5rem 2.5rem;
}
.publish-success {
    width: 28rem;
}
.wysiwig-editor{
    min-height: 22.75rem;
    border:1px solid #ced4da
}

.wysiwig-editor .toolbar-box{
    border-bottom:1px solid #ced4da;
    border-left:0;
    border-right:0;
    border-top:0;  
}
.wysiwig-editor:hover, 
.wysiwig-editor:hover .toolbar-box{
    border-color: var(--primary);
}
.wysiwig-editor .toolbar-box .rdw-option-active,
.toolbar-box .rdw-option-wrapper:hover ,
.toolbar-box .rdw-dropdown-wrapper:active,
.toolbar-box .rdw-dropdown-wrapper:hover {
    box-shadow:  1px 1px 0px var(--primary);
}
.toolbar-box .rdw-image-modal{
    left: -85px;
}
.toolbar-box .rdw-emoji-modal {
    left:-44px
}
.toolbar-box .rdw-embedded-modal {
    left:-10px
}
.toolbar-box .rdw-image-modal-header-label-highlighted,.rdw-embedded-modal-header-label {
    border-bottom: 2px solid var(--primary);
}
.toolbar-box .rdw-link-modal{
    height: auto;
}
.toolbar-box input:hover{
    border:1px solid var(--primary)
}

.rdw-editor-main {
    min-height: 22rem;
}
.rdw-editor-main blockquote{
    border-left: 6px solid var(--primary);
    padding-left: 19px;
}
.rdw-image-imagewrapper img{
    max-width: 100%;
}
.rdw-image-modal-size,
.rdw-embedded-modal-size
 { display: none; }

 .rdw-editor-main iframe
 {
    width: 100%;
    height: 350px;
 }

 @media (max-width: 575px) {
    .rdw-editor-main iframe {
        height: 250px;
    }
 }

.file-upload{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 3em;
}
.file-upload button{
    font-weight: bold;
    color: #212121;
}
.file-upload p{
   border-right:solid 1px #eaedf3
}
.file-upload .input-file{
    position: absolute;
    width: 94%;
}
.file-upload:hover{
    border-color: var(--primary);
}
.dropzone{
    height: 22.75rem;
    border-radius: 3px;
    border: dashed 2px #e4e4e4;
    background-color: #fffefa;
}
.dropzone:focus{
  border: dashed 2px var(--primary);
  outline: none;
}

/* #keyword-inputs input.form-control {
    background: none;
    border: none;
    font-size: 1.25rem;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    padding: .5rem 0;
} */

#keyword-inputs .keyword-input-wrapper::after {
    content: 'Press "enter/return" to add keyword';
    position: absolute;
    font-size: 0.75rem;
    right: 0;
    bottom: -1.25rem;
}

#keyword-inputs input.form-control::-webkit-input-placeholder { color: #9c9c9c; }

#keyword-inputs input.form-control::-moz-placeholder { color: #9c9c9c; }

#keyword-inputs input.form-control::-ms-input-placeholder { color: #9c9c9c; }

#keyword-inputs input.form-control::placeholder { color: #9c9c9c; }
#keyword-inputs input.form-control:focus { 
    color: #000;
    border-color: var(--primary);
}

.selected-keywords span {
    background: #eaedf3;
    color: #212121;
    padding: 0.25rem 0.825rem;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    margin-right: .5rem;
    margin-bottom: .5rem;

}
.selected-keywords span > img {
    height: .625rem;
    margin-left: .25rem;
    margin-bottom: .1rem;
    cursor: pointer;
}
.session-request-wrapper {
    position: relative;
}
#session-request-popover {
    position: absolute;
    top: 3.5rem;
    right: -2.5rem;
    background-color: #fff;
    z-index: 100;
    border: 1px solid #dcdcdc;
    border-radius: 0.25rem;
    min-width: 30rem;
    box-shadow: 0 0 14px 0 rgba(3, 16, 32, 0.27);

}
.session-requests .title {
    color: #212121;
    font-weight: 600;
    border-bottom: 1px solid #dcdcdc;
    font-size: 1rem;
}
.session-requests {
    position: absolute;
    top: 2.5rem;
    right: -2.5rem;
    background-color: #fff;
    z-index: 100;
    border: 1px solid #9c9c9c;
}
.session-request-item:not(:last-child) {
    border-bottom: 1px solid #dcdcdc;
}
.session-request-item .profile-image {
    height: 2.75rem;
    width: 2.75rem;
}
.session-request-item.new-item {
    background-color: #f4eee5;
}
.session-request-item .request-by {
    color: #212121;
    font-size: 1rem;
    font-weight: bold;
}
.session-request-item .date {
    font-size: 0.75rem;
    color: #9c9c9c
}
button.accepted {
    color: #876d16 !important;
    white-space: nowrap;
    background-color: rgba(212, 175, 55, 0.5);
    font-weight: bold;
    cursor: default;
}
button.accepted svg { margin-right: 0.5rem; }

.session-notification-indicater {
    display: inline-block;
    height: 0.375rem;
    width: 0.375rem;
    background-color: #4392f1;
    position: absolute;
    top: -0.2rem;
    right: -0.325rem;
    border-radius: 50%
}
#session-request-popover::before {
    content: '';
    position: absolute;
    top:0;
    right: 2.25rem;
    width: 1rem;
    height: 1rem;
    background: #fff;
    -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
}
@media (max-width: 991px) {
    #session-request-popover {
        top: 2rem;
        right: unset;
        z-index: 1000;

    }
    #session-request-popover::before {
        display: none;
    }
}
@media (max-width: 536px) {
    /* .session-request-wrapper {
        position: static;
    } */
    #session-request-popover {
        min-width: 90vw;
        top: 2.5rem;
        right: 1rem;
        left: -2rem;
        z-index: 1000;
    }
}
.notification-wrapper {
    position: relative;
}
#notifications-popover{
    position: absolute;
    top: 3.5rem;
    right: 0;
    background-color: #fff;
    z-index: 200;
    border: 1px solid #eaedf3;
    border-radius: 0.25rem;
    min-width: 30rem;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12);
}
#notifications-popover::before {
    content: '';
    position: absolute;
    top:0;
    right: 1.25rem;
    width: 1rem;
    height: 1rem;
    background: #fff;
    border-left: 1px solid #eaedf3;
    border-top: 1px solid #eaedf3;
    /* box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12); */
    z-index: -1;
    -webkit-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
}
.notifications {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.notification-header { border-bottom: 1px solid #eaedf3; }
.notification-header .title {
    color: #212121;
    font-weight: 600;
    font-size: 1rem;
}
.notification-item:not(:last-child){
    border-bottom: 1px solid #eaedf3;
}
.notification-item.read {
    background-color: #f4eee5;
}
.notification-item .profile-image{
    height: 2.75rem;
    width: 2.75rem;
    min-width: 2.75rem;
}
.notification-item .message {
    color: #9c9c9c;
    line-height: 1.3;
}
.notification-item .message .sender {
    color: #212121;
    font-weight: bold;
}
.notification-item .date{
    font-size: 12px;
    color: #9c9c9c;
}
.btn-link.gt-btn-link {
    color: var(--primary)
}
.notification-indicater {
    display: inline-block;
    height: 0.375rem;
    width: 0.375rem;
    background-color: #4392f1;
    position: absolute;
    top: 0;
    right: 1.25rem;
    border-radius: 50%
}

@media (max-width: 991px) {
    #notifications-popover {
        /* position: static; */
        top: 2rem;
        right: unset;
    }
    #notifications-popover::before {
        display: none;
    }
}
@media (max-width: 536px) {
    /* .notification-wrapper {
        position: static;
    } */
    #notifications-popover {
        min-width: 92vw;
        /* right: 2vw; */
        left: 0;
        top: 2.5rem;
    }
}
.category-view .heading{
    font-size: 3.0625rem;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}
.category-view .count{
    font-size: 21px;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
}
.category-view .type{
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    color: #ffffff;
}
.category-view .navbar-search input ,
.category-view  .search-drop-down,
.category-view .navbar-search ul > li {
    font-size: 14px;
   
}
 .tabs{
    border-bottom: solid 1px #eaedf3;
    background-color: #ffffff;
}

.tabs a{
    border-left: 0 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    border-radius:none;
    border-right:1px solid #eaedf3 !important;
    opacity: 0.5;
    font-size: 16px;
    line-height: 1.5;
    color: #9c9c9c;
    font-weight: bold;

}
.tabs a:last-child{
    border-right: 0 !important;
}

.nav-tabs .nav-link.active span{
    color: #d4af37 !important;
  
}
.nav-tabs .nav-link.active span svg path{
    fill: #d4af37 !important;
    opacity: 1 !important;
}
.nav-tabs .nav-link.active{
    opacity: 1 !important;
}
.filtered-content .section-header h4{
    font-family: Source Sans Pro;
}
.slick-slide{
    padding-right: 15px;
    padding-left: 15px;
}
.category-content .article-card:hover{
    -webkit-transform: translateY(0rem) !important;
            transform: translateY(0rem) !important;    
    box-shadow: none !important;
}

.category-content .article-card:hover .article-image {
    background-image:none !important
}

.category-content  .article-card:hover .article-image > img {
    z-index: none;
}
.category-content  .practitioner-card:hover{
    -webkit-transform: translateY(0rem) !important;
            transform: translateY(0rem) !important;    
    box-shadow: none !important;
}
#settings::-webkit-scrollbar {
    width: 0px;
    height: 0px;
 }
 
 #settings::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
 }
 
 #settings::-webkit-scrollbar-thumb {
    height: 100px;
    background-color: #d8d4d4;
    outline: 1px solid slategrey;
 }
#prac_profile {
  background-color: #f2f4f8;
  min-height: 100vh
}
/* #prac_profile .nav_container {
  background-color: #ffffff;
  border-bottom: solid 1px #eaedf3;
} */
#prac_profile .prac_tags {
  padding-left: 1rem;
}

#prac_profile .prac-profile-card {
  background-color: #ffffff;
  border: 1px solid #eaedf3;
  border-radius: .25rem;
  /* margin-top: 1.25rem; */
}
#prac_profile .prac_basic_info {
  padding-top: 2.5rem;
  /* border: solid 1px #eaedf3;
  border-radius: 3px; */
}
#prac_profile .prac_about_pic img{
  border-radius: 50%;
  height: 9rem;
  width: 9rem;
  object-fit: cover;
  border: 1px solid #eaedf3;
}
#prac_profile .prac_name {
  font-weight: 600;
  font-size: 1.3rem;
  text-transform: capitalize;
}
#prac_profile .prac_category {
  font-size: 0.75rem;
  color: #909090;
  margin-bottom: 1rem;
  text-transform: capitalize;
}
/* #prac_profile .prac_info_2 {
  border: solid 1px #eaedf3;
} */
#prac_profile .prac_about_section{
  border-bottom: solid 1px #eaedf3;
}
#prac_profile .prac_about_section p {
  font-weight: 600;
  font-size: 0.75rem;
}
#prac_profile .prac_info_2 {
  color: #bfbfbf;
}
#prac_profile .prac_about_me span {
  font-size: 0.75rem;
  font-style: italic;
}
#prac_profile .prac_category span:not(:last-child)::after {
  content: ',';
  margin-right: 0.25rem;
}
#prac_profile .prac_contact_info {
  font-size: 0.85rem;
  word-break: break-word;
}
#prac_profile .prac_about_section button {
  border: none;
  color: #ccb385;
  font-size: 0.75rem;
  background-color: #ffffff;
}
#prac_profile .prac_about .connect_button button, #prac_profile .prac_about .connect_button button:hover {
  background-color: #b99458;
  color: #fff;
  border-radius: 0.375rem;
  width: -webkit-fill-available;
  font-weight: 600;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  outline: none;
}

.btn-outline-white {
  border: 1px solid #e6e6e6;
  color: #9c9c9c;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  
}
.btn-outline-white:hover {
  /* color: var(--primary); */
  /* font-weight: 600; */
  background-color: #FFF9E6;
}
#prac_profile .prac_carousel {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 213, 146, 0.801)), to(rgba(185, 148, 88, 0.801))), url(/images/prac_carousel.png);
  background-image: linear-gradient(to bottom, rgba(255, 213, 146, 0.801), rgba(185, 148, 88, 0.801)), url(/images/prac_carousel.png);
  background-size: cover;
  background-position: top;
  height: 10.56rem;
  padding: 1.125rem;
  font-family: SourceSansPro;
}
#prac_profile .prac_carousel h4{
  color: #fff;
  font-weight: 600;
}
#prac_profile .prac_carousel p {
  color: #ffffff;
  font-size: 1.125rem;
}
#prac_profile a.carousel-control-prev,
#prac_profile a.carousel-control-next {
  display: none !important;
} 
#prac_profile .prac_carousel .offer_days_left p{
  font-size: 0.935rem;
}
#prac_profile .prac_carousel .offer_image_div{
  height: 7.375rem;
  width: 7.375rem;
  background-color: #aa874e;
  border-radius: 0.25rem;
  padding-top: 0.75rem;
  text-align: center;
}
#prac_profile .prac_carousel .offer_image_div img{
  height: 4.125rem;
  width: 4.125rem;
}
#prac_profile .prac_carousel .offer_image_div p{
  opacity: 0.5;
  font-family: SourceSansPro;
  font-size: 0.875rem;
  letter-spacing: 3.9px;
  color: #fff;
  margin-top: 0.875rem;
}
.prac_user_offers .carousel-indicators li {
  border: none;
  height: .5rem;
  width: .5rem;
  border-radius: 50%;
}
.prac_user_offers .carousel-indicators .active {
  background-color: #000;
}
#prac_profile .prac_availability_header button{
  border: none;
  color: #ccb385;
  font-size: 0.75rem;
  background-color: #fff;
}
#prac_profile .prac_availability {
  background-color: #ffffff;
}
#prac_profile .prac_availability_header {
  padding: 1rem;
  border: 1px solid #eaedf3;
  border-bottom: 0;
}
#prac_profile .prac_availability_header h6{
  font-weight: 600;
}
#prac_profile .prac_availability_body {
  padding: 1rem;
  border: 1px solid #eaedf3;
}
#prac_profile .prac_availability_body p{
  font-size: 0.75rem;
  color: #909090;
}
#prac_profile .prac_avail_slider p{
  font-size: 0.75rem;
  font-weight: 600;
  color: #484848;
}
#prac_profile .prac_schedule_section p{
  text-transform: uppercase;
  color: #031121;
  font-weight: 600;
}
#prac_profile .prac_schedule_section > div > p{
  color: #909090;
}
#prac_profile .prac_offers {
  background-color: #ffffff;
}
#prac_profile .prac_offers_header {
  padding: 1rem;
  border: 1px solid #eaedf3;
  border-bottom: 0;
}
#prac_profile .prac_offers_header h6{
 font-weight: 600;
}
#prac_profile .prac_offers_body_container {
  padding: 1rem;
  border: 1px solid #eaedf3;
}
#prac_profile .prac_offers_body {
  background-color: #EEA84936;
  padding-top: 0;
  background-image: url('/icons/percentage.svg');
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: 174px -19px;
  background-size: 65px;
  border-radius: 3px;
}
#prac_profile .prac_offers_body:not(:last-child){
  margin-bottom: 1rem;
}
#prac_profile .prac_offers_body h6{
  color: #b99458;
  font-weight: 600;
  font-size: 0.9rem;
}
#prac_profile .prac_offers_body p{
  color: #b99458;
  font-size: 0.75rem;
  margin-bottom: 0;
} 
#prac_profile .prac_offers_body .date{
  background-color: #B99458;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
} 
@media (max-width: 576px) {
  #prac_profile .prac_offers_body {
    background-position: right -19px;
  }
}
#prac_profile .prac_location_map .map{
  height: 15rem;
  border: solid 1px #eaedf3;
}
#prac_profile .prac_location_map .ol-overlaycontainer-stopevent{
  position: absolute;
  display: none;
}
#prac_profile .prac_location_map .map_details h6{
  color: #031121;
  font-weight: 700;
}
#prac_profile .prac_location_map .map_details p{
  color: #808080;
  font-size: 0.7rem;
}
#prac_profile .prac_location_map .prac_location_body{
  background-color: #fff;
  border: solid 1px #eaedf3;
  border-top: none;
}
#prac_profile .prac_location_map .map_icon{
  background-color: #d4af37;
  border-radius: 50%;
}
#prac_profile .prac_content{
  background-color: #ffffff;
  border: solid 1px #eaedf3;
}
#prac_profile .prac_content h6{
  background-color: #ffffff;
  padding: 1rem;
  font-weight: 600;
  color: #031121;
}
#prac_profile .prac_content_header{
  color: #031121;
  font-weight: 600;
}
#prac_profile .content_navbar li{
  margin-right: 2rem;
  list-style: none;
  color: #a2a2a2;
  font-size: 0.75rem;
  font-weight: 600;
}
#prac_profile .content_navbar {
  border-bottom: solid 1px #eaedf3;
  margin-bottom: 0;
}
#prac_profile .prac_content_search {
  border-bottom: solid 1px #eaedf3;
}
#prac_profile .tabs{
  border-bottom: solid 1px #eaedf3;
  background-color: #ffffff;
}
#prac_profile .nav-link {
  border-right: none !important;
}
#prac_profile .prac_content .tabs a{
  opacity: 0.5;
  font-size: 0.75rem;
  color: #a2a2a2;
  font-weight: 600;

}
#prac_profile .prac_content .nav-tabs .nav-link{
  padding: 0.5rem;
}
#prac_profile .prac_content .nav-tabs .nav-link.active span{
  color: #031121 !important;
}
#prac_profile .prac_content .nav-tabs .nav-link.active span svg path{
  fill: #031121 !important;
  opacity: 1 !important;
}
#prac_profile .prac_content .nav-tabs .nav-link.active{
  opacity: 1 !important;
}
#prac_profile .content_load {
  font-size: 0.75rem;
  color: #d4af37;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
#prac_profile .prac_content .tabs a.active{
  border-bottom: 0.25rem solid #ccb385 !important;
  border-top-left-radius: 4px;
}
.content-tab-container {
  max-height: 25rem;
  overflow-y: auto;
  -webkit-transform: translate3d(0,0,0);
}

.content_section {
  background-color: #ffffff;
  height: 9rem;
  overflow: hidden;
  margin-bottom: 0.5rem;
  /* padding: 1rem; */
  /* border-bottom: solid 1px #eaedf3; */
}
.content_icon{
  height: 100%;
  width: 9.25rem;
  object-fit: cover;
}
.content-type-icon{ 
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 10;
}
.content-thumbnail {
  height: 11.25rem;
  width: 11.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.content_section_desc h5{
  font-size: 1.15rem;
  font-weight: 600;
  color: #212121;
}
.content_section_desc p{
  color: #9c9c9c;
  overflow: hidden;
  font-size: 14px;
  max-width: 100% !important;
  /* autoprefixer: off; */
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  word-break: break-all;
  /* width: 346px; */
}
.content_section_desc {
  /* padding: 1rem;
  padding-top: 0; */
}
.content_section_footer {
  font-size: 0.75rem;
  color: #212121;
}
.content_footer_right {
  color: #9c9c9c;
}
.content_rating span {
  margin-left: 0.25rem;
}
.content_footer_date::before{
  content: '\2022';
  margin: 0 4px;
}
.content_section .content-popover {
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  right: 0.75rem;
  top: 1.75rem;
}

@media (max-width: 576px) {
  .content_section { height: 7.25rem; }
  .content_icon { width: 7.25rem; }
}
.change-thumbnail {
    position: relative;
    height: 230px;
}
.change-thumbnail .file-change {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transition: .2s;
    transition: .2s;
    /* cursor: pointer; */
    background-color: rgba(0, 0, 0, 0.424);
}
.change-thumbnail:hover .file-change {
    opacity: 1;
}
.change-thumbnail img.thumbnail {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
#prac_profile .prac_review{
  background-color: #ffffff;
  margin-top: 1.25rem;
  border: solid 1px #eaedf3;
}
#prac_profile .prac_review_header h6{
  background-color: #ffffff;
  padding: 1rem;
  font-weight: 600;
  color: #031121;
}
#prac_profile .prac_review_header{
  color: #031121;
  font-weight: 600;
  padding: 1rem;
  border-bottom: 0;
}
#prac_profile .reviewer_icon img {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}
#prac_profile .reviewer_details h6{
  font-weight: 700;
  color: #031121;
}
#prac_profile .reviewer_details_bottom p{
 margin-bottom: 0;
 color: #bfbfbf;
 font-size: 0.75rem;
}
#prac_profile .prac_review_body p{
  color: #9c9c9c;
  white-space: pre-line;
}
/* #prac_profile .reviewer_details_bottom .published_date::before{
  content: '\2022';
  margin: 0 4px;
} */
.prac_review .review_load {
  font-size: 0.75rem;
  color: #d4af37;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.card_block {
  max-height: 25rem;
  overflow-y: auto;
}
.card_block .prac_review_card:not(:last-child) .prac_review_content {
   border-bottom: solid 1px #eaedf3;
}
.prac_review_card .reviewer_icon img {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  object-fit: cover;
}
.prac_review_card .reviewer_details h6{
  font-weight: 700;
  color: #031121;
}
.prac_review_card .reviewer_details_bottom p{
 margin-bottom: 0;
 color: #bfbfbf;
 font-size: 0.75rem;
}
.prac_review_card .prac_review_body p{
  /* border-bottom: solid 1px #eaedf3; */
  color: #9c9c9c;
  font-size: 0.875rem;
}
/* .prac_review_card .reviewer_details_bottom .published_date::before{
  content: '\2022';
  margin: 0 4px;
} */
#prac_profile .prac_business{
  background-color: #ffffff;
  margin-top: 1.25rem;
  border: solid 1px #eaedf3;
}
#prac_profile .prac_business_header h6{
  background-color: #ffffff;
  padding: 1rem;
  font-weight: 600;
  color: #031121;
}
#prac_profile .prac_business_header{
  color: #031121;
  font-weight: 600;
  padding: 1rem;
  border-bottom: 0;
}
#prac_profile .prac_buisness_details {
  margin-right: 1rem;
  margin-left: 1rem;
  border-bottom: solid 1px #eaedf3;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
#prac_profile .prac_buisness_details p {
  font-size: 0.75rem;
  margin-bottom: 0;
  color: #9c9c9c;
}
#prac_profile .prac_buisness_details img{
  height: 3.625rem;
  width: 3.625rem;
}
#prac_profile .prac_buisness_details h5{
  font-size: 1.31rem;
  font-weight: 600;
}
#prac_profile .prac_members p{
  font-size: 0.75rem;
  color: #bfbfbf;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1rem;
}
#prac_profile .prac_mem_count {
  height: 3rem;
  width: 3rem;
  background-color: #d4af37;
  margin-left: -0.725rem;
  border-radius: 50%
}
#prac_profile .prac_mem_count p{
  color: #fff !important;
  size: 1rem;
}
#prac_profile .prac_mem_icon img {
  height: 3rem;
  width: 3rem;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #eaedf3;
}
#prac_profile .prac_mem_icon{
  margin-left: -0.725rem;
}

#prac_profile .prac_mem_icon:hover::before {
  content: attr(data-name);
  position: absolute;
  color: #fff;
  background-color: #222222;
  padding: .25rem .65rem;
  font-size: 0.75rem;
  top: 1.5rem;
  border-radius: 0.25rem;
}

.practitioner-license-card {
    background: #fff;
    border-radius: 0.25rem;
}
.practitioner-license-card .practitioner-license-card-header {
    border-bottom: 1px solid #eaedf3;
}
.practitioner-license-card .practitioner-license-card-header h6 {
    font-weight: bold;
    margin: 0
}
.practitioner-license-card .certificate {
    background-color: rgb(185, 148, 88, 0.21);
    border-radius: .25rem;
    color: #b99458;
}
.practitioner-license-card .certificate:not(:last-child) { margin-bottom: 1rem}
.practitioner-license-card .certificate p:first-child {
    font-weight: bold;
}
.practitioner-license-card .certificate p:nth-child(2){
    font-size: 0.75rem;
}

.radial-chart {
    position: relative;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}
.radial-chart.no-progress .radial-chart-progress {
    opacity: 0;
}
.radial-chart-total {
    opacity: 0.3;
}
.radial-chart-progress {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
    transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
    -webkit-transition-delay: 0.3s;
            transition-delay: 0.3s;
}
.radial-chart-title{
    font-size: 1rem;
    color: #212121;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: -0.625rem;
    /* position: absolute;
    left:50%;
    transform: translateX(-50%);
    bottom:-8px; */
}
#assessment-result {
    background-color: #f2f4f8;
    min-height: 100vh;
}

.result-overview {
    min-height: 20rem;
    border-bottom: 1px solid #eaedf3;
}
.result-overview-total {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #fff;
    width: 2.325rem;
    height: 2.325rem;
    border-radius: 50%;
    text-align: center;
    padding: 0.5rem;
    font-size: 0.825rem;
}
/* .result-overview-header,
.result-overview-body { 
    border-bottom: 1px solid #eaedf3
}
.result-overview-header h6{
    font-weight: bold;
    margin: 0
}

.result-overview-footer ul li {
    list-style: none;
    color: #212121;
    text-transform: capitalize;
}

.result-overview-footer ul li .legend{
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: 0.75rem;
    margin-bottom: 1px;
} */

/* RESULT */

.assessment-result {
    background-color: #fff;
    border-radius: 0.25rem;
}

.assessment-result ul li {
    list-style: none;
    color:#9c9c9c;
    text-transform: capitalize;
    padding: 0.625rem 1rem;
}
.assessment-result ul li > span{ float: right; }
.assessment-result ul li:hover {
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
}

.assessment-result ul li:last-child {
    background-color: #eaedf3;
    color: #212121;
    font-weight: 600;
    
}

.assessment-result .dropdown .dropdown-toggle {
    width: 100%;
    height: 100%;
    border-radius: 0;
    border: none;
    background: #fff;
    color: unset;
    border-bottom: 1px solid #eaedf3;
    text-transform: capitalize;
    padding: 0.5rem 0.75rem;
}

.assessment-result .dropdown .dropdown-toggle:active {
    color: unset;
    background-color: #fff;
    border-color: #eaedf3;
}

.assessment-result .dropdown .dropdown-menu {
    right: 0;
    border-radius: 0;
    border-color: #eaedf3;
}

/* DESCRIPTION CARD */

#assessment-result .wholeness-description-card,
#assessment-result .helpful-resouces
{
    background: #fff;
    border-radius: 0.25rem;
    padding: 1rem;
}
#assessment-result .wholeness-description-card h1 {
    font-size: 2rem;
    color: #212121;
    font-weight: bold;
    text-align: left;
}
#assessment-result .wholeness-description-card p {
    color: #9c9c9c;
    line-height: 1.5;
}

/* @media (min-width: 1600px) {

    #assessment-result .container {
        max-width: 1300px;
    }
} */

.assessment-status-chart {
    background-image: -webkit-gradient(linear, left top, right top, from(#c5c5c5), to(#e9e9e9));
    background-image: linear-gradient(to right, #c5c5c5, #e9e9e9);
    height: 1.25rem;
    position: relative;
}
.assessment-status-chart .asessment-score {
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%) translateY(-115%);
            transform: translateX(-50%) translateY(-115%);
    left: 56%;
    color: #fff;
    font-weight: 600;
    background-color: var(--primary);
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    z-index: 10;
    text-align: center;
    line-height: 1.9;
    /* margin-bottom: 0.25rem; */
}
.assessment-status-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    height: 100%;
}
.assessment-status-chart .assessment-status {
    width: 10%;
    height: 100%;
}
.assessment-status-chart .assessment-status:not(:last-child) {
    border-right: 1px solid #fff;
}


.assessment-status-chart .assessment-status .status-number {
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
}
.assessment-status-chart .assessment-status .status-progress {
    position: absolute;
    height: 100%;
    /* width: 100%; */
    background-color: var(--primary);
}
.assessment-status-chart .assessment-status .status-text {
    position: absolute;
    bottom: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}

.assessment-result-tab nav {
    padding-top: 1rem;
}

@media (max-width: 576px) {
    .assessment-status-chart .assessment-status .hide{
        display: none;
    }
}
#user-profile {
  background-color: #f2f4f8;
  min-height: 100vh;
}
.heading {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 37px;
  line-height: 47px;
  color: #212121;
}
.questions {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #031121;
  width: 100%;
}
.answer {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9c9c9c;
}
.custom-select {
  font-size: 16px;
  border: 1px solid #d4af37 !important;
}
.nopadding {
  padding: 0 !important;
}
.result-title {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #212121;
}
.score-title {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 37px;
  line-height: 56px;
  color: #212121;
}
.optional{
  margin-left: 15px;
  width: 96%;
}
.showbtn{
    background-color:white;
    display: block;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    color:#d4af37;
    border:none
}



.user-card {
    background: #fff;
    border-radius: .25rem;
}
/* .user-card-body {
    border-bottom: 1px solid #eaedf3;
} */
/* .user-card-body img[alt='profile-pic'] {
    margin-bottom: .5rem;
    max-height: 10rem;
    border-radius: 50%;
}
.user-card-body img[alt='premium']{
    height: 0.85rem;
    margin-left: .5rem; 
} */
.user-card-body .user-name{
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom:0;
    line-height: 1;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}
.gt-card {
    background: #fff;
    border-radius: 0.25rem;
}
.gt-card .gt-card-header { border-bottom: 1px solid #eaedf3}
.gt-card .gt-card-header h6 {
    font-weight: bold;
    margin: 0
}
.progress-check-in-card {
    border-radius: 0.25rem;
    color: #fff;
    background-image: linear-gradient(317deg, rgb(212, 148, 55), rgb(212, 175, 55));
}
.progress-check-in-card .gt-link:hover {
    color: var(--primary);
}

.progress-check-in-card h4{
    font-size: 1.25rem;
    font-weight: bold;
}

.progress-check-in-card .start-now {
    border: 1px solid #fff;
    color: #fff;
    border-radius: 40px;
}
.contacts-card {
    background: #fff;
    border-radius: 0.25rem;
}
.contacts-card .contacts-card-header h6 {
    font-weight: bold;
    margin: 0
}

.contacts-card-body .empty-meaage{
    font-size: 0.75rem;
    color: #9c9c9c;
}

/* AVATAR */
.contacts-card .avatarr p {margin: 0}
.contacts-card .avatarr .specialisation{
    color: #9c9c9c;
    font-size: 0.75rem;
}
.contacts-card .avatarr .specialisation span:not(:last-child)::after{
    content: ',';
    margin-right: .25rem;
}
.contacts-card .avatarr .avatar {
    height: 2.75rem;
    width: 2.75rem
}
.primary-link-btn{
    color: var(--primary);
    font-size: 0.75rem;
    font-weight: 600;
}
.btn-browse-pract{
    width: 100%;
   }
@media(max-width:992px){
    .btn-browse-pract{
    width: unset;
    }
}
.content-tab-card{
    background: #fff;
    border-radius: 0.25rem;
}
.content-tab-card nav {
    border-color: #eaedf3;
}
.content-tab-card .nav-tabs .nav-link{
    border: none;
    border-radius: 0;
    color: #a2a2a2;
    font-size: 0.75rem;
    font-weight: 700;
}
.content-tab-card .nav-tabs .nav-link.active{ 
    color: #222222;
    outline: none;
    border-bottom: 4px solid var(--primary)
}
.content-tab-card .nav-link {
    padding: 0;
    margin: 0.5rem 1rem;
    padding-bottom: 0.325rem;
    margin-bottom: 0;
}
.content-tab-card .title {
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
}
.content-tab-card .saved-content-tab {
    max-height: 20rem;
    overflow: auto;
}
.content-list-card .card-content-img{
    height: 5.937rem;
    width: 5.937rem;
    border-radius: 3px;
    min-width: 5.937rem;
    box-sizing: content-box;
    object-fit: cover;
}
.content-list-card h6{
    font-weight: 600;
   /* autoprefixer: off */
   -webkit-box-orient: vertical;
   display: block;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   overflow: hidden;
   text-overflow: ellipsis
}
.content-list-card .type-icon{
    position: relative;
    top: 1.7rem;
    left: 0.5rem;
    z-index: 80;
}
.report-content{
    border-bottom: 1px solid #eaedf3;
    /* border-top: 1px solid #eaedf3; */
    -webkit-transition: 1s;
    transition: 1s;
}
.report-content h6{
    font-weight: 600
}
.report-content .radio-container {
    display: block;
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: .75rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .report-content .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .report-content .checkmark {
    position: absolute;
    top: 0.34rem;
    left: 0;
    height: 0.875rem;
    width: 0.875rem;
    background-color: #fff;
    border-radius:.75rem;
    border: solid 1.5px #9c9c9c
  }

  .report-content .radio-container:hover input ~ .checkmark {
    background-color: #FAF6E8;
  }
  .report-content .radio-container input:checked ~ .checkmark {
     border-color: var(--primary)
     
  }
  .report-content .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .report-content .radio-container input:checked ~ .checkmark:after {
    display: block;
  }
  .report-content  .radio-container .checkmark:after {
    left: 1px;
    top: 1px;
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--primary);
    border-radius:.75rem;
  }
  .report-content .btn-next{
      color:  var(--primary)
  }
  .report-content .form-control:focus{
    border-color: #dedede;
  }
  .report-content .complete-report p{
    font-size: .75rem;
    color: #9c9c9c
  }
.user-comments  img{
    height: 1.875rem;
    width: 1.875rem;
}
.user-comments .time,
.user-comments .comment-text{
    color: #9c9c9c;
    font-size: 0.75rem;
    white-space: pre-line;
}

.commentComponent{
  border-bottom:  1px solid #eaedf3;
}

  .commentComponent h6{
    font-weight: 600;
  }
  .commentComponent textarea::-webkit-input-placeholder{
      font-size: 0.75rem;
      color: #9c9c9c;
  }
  .commentComponent textarea::-moz-placeholder{
      font-size: 0.75rem;
      color: #9c9c9c;
  }
  .commentComponent textarea::-ms-input-placeholder{
      font-size: 0.75rem;
      color: #9c9c9c;
  }
  .commentComponent textarea::placeholder{
      font-size: 0.75rem;
      color: #9c9c9c;
  }
  .comentBox:focus{
    border: solid 1px var(--primary);
    outline: none;
    box-shadow: none;
  }
.article-view .recommented-view .heading {
  /* border-top: 1px solid #f2f4f8; */
  border-bottom: 1px solid #f2f4f8;
}
.article-view .content-title {
  font-size: 2.312rem;
}
img.thumbnail-image {
  height: 25rem;
  object-fit: cover;
}
.article-view .premium-overlay {
  position: absolute;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background-color: white;
  opacity: 0.7;
}
.article-view .recommented-view h5 {
  font-size: 21px;
  font-weight: bold;
}
.article-view .article-card:hover {
  -webkit-transform: translateY(0rem) !important;
          transform: translateY(0rem) !important;
  box-shadow: none !important;
}

.article-view .article-card:hover .article-image {
  background-image: none !important;
}

.article-view .article-card:hover .article-image > img {
  z-index: none;
}
.article-view .trending-categories h6 {
  font-size: 1.312rem;
  font-weight: bold;
  border-bottom: 1px solid #eaedf3;
}

.article-view .user-details img {
  height: 2.25rem;
  width: 2.25rem;
  object-fit: cover;
  border-radius: 50%;
}
.trending-categories a {
  text-decoration: none;
  color: #9c9c9c;
}
.article-view .user-details .date,
.article-view .user-details p {
  font-size: 0.75rem;
  color: #9c9c9c;
}

.article-view blockquote {
  border-left: 6px solid #d4af37;
  padding-left: 19px;
}

.article-view .review-box {
  border-bottom: 1px solid #eaedf3;
}
.article-view .review-box .col {
  height: 2px;
  background: #eaedf3;
}
.article-view .review-box .rectangle {
  width: 9.4px;
  height: 9.4px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  background-color: #eaedf3;
}
.article-view .review-box p {
  font-size: 0.75rem;
}

.article-section img,
.article-section iframe
{
  max-width: 100%;
}
.article-section iframe {
  width: 100%;
  height: 350px;
}

.video-content #bgvid {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  object-fit: cover; /* cover works perfectly on Safari */
}

.video-content .wrapper {
  width: 100%;
  min-width: 100%;
  height: 364px;
  max-height: 364px;
  overflow: hidden;
}
.article-view .content-description {
  color: #9c9c9c;
  padding-left: 2.8rem;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;

  text-overflow: ellipsis;
}
.article-view .see-more {
  padding-left: 2.8rem;
  color: var(--primary);
  font-weight: bold;
  cursor: pointer;
}
.article-view .catagory-chips {
  border-radius: 3px;
  background-color: #f4f4f4;
  cursor: pointer;
}
.article-view .share-icons .fb,
.article-view .share-icons .twitter {
  color: #212121;
  font-size: 1.25rem;
}
.article-view .share-icons a {
  text-decoration: none;
}
.article-view .share-icons .fb:hover {
  cursor: pointer;
  color: #385798;
}
.article-view .share-icons .twitter:hover {
  cursor: pointer;
  color: #00a0ed;
}

.article-view pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 10px 10px;
}

[class*="_1d7iuxk"],
[class*="_1d7iuxk"]:hover {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
._i5w4tsi,
[class*="track"]
[class*="bar"]:nth-child(2) {
  background-color: var(--primary) !important;
}

@media (max-width: 575px) {
  img.thumbnail-image {
    height: 18rem;
  }
  .video-content .wrapper {
    height: 250px;
  }
  .article-section iframe {
    height: 250px;
  }
  .article-view .content-title {
    font-size: 1.75rem;
  }
}

.purchase-modal {
  max-height: 40rem;
  -webkit-transition: 1s;
  transition: 1s;
  overflow: hidden;
}

.purchase-modal-content-hide {
  max-height: 0rem;
  opacity: 0;
}

.purchase-modal .purchase-head-image {
  height: 19.2rem;
  /* overflow: hidden; */
}

.purchase-modal .purchase-head-image .head-image-size,
.purchase-modal-payment .head-image-size {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.purchase-modal .preimum-icon-position {
  position: absolute;
  bottom: -2.5rem;
}

.purchase-modal .preimum-icon-container {
  width: 5.6rem;
  height: 5.6rem;
  background-image: linear-gradient(315deg, #d49437, #d4af37);
  border-radius: 5rem;
}

.purchase-modal-footer {
  background-color: #f6f6f6;
  border-top: none;
}

.purchase-modal .navitem.active {
  background-color: #777777;
}

.purchase-modal .nav-tabs a {
  color: #9c9c9c !important;
}

.purchase-modal .nav-tabs .nav-link:hover {
  border-color: transparent;
}
.purchase-modal .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #d4af37 !important;
  font-weight: 600;
  border-color: transparent;
  border-bottom: solid 1px #d4af37;
  background-color: transparent;
}

.purchase-modal .nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 1rem;
  margin-left: 1rem;
}

.purchase-modal-payment .label {
  font-size: 0.75rem;
}

.purchase-modal-payment .feed-description {
  line-height: 1.5;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.purchase-modal-payment-type {
  -webkit-transform: translateY(5rem);
          transform: translateY(5rem);
  opacity: 0;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  height: 0px;
}

.purchase-modal-payment-type-show {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
  height: 350px;
}
.purchase-modal .purchase-back-button {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  text-decoration: none;
  background-color: #fff;
  height: 1.75rem;
  width:  1.75rem;
}
.purchase-back-button i {
  font-size: .75rem;
}

.card-container {
  border-radius: 4px;
  border: solid 1px #eaedf3;
  background-color: #ffffff;
}

.card-container-membership {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-width: 0px;
}

.delete:hover path {
  fill: #6b6b6b;
}

.card-icon {
  width: 2rem;
}

.default-card {
  font-size: 0.65rem;
  background-color: #d4a002;
  border-radius: 0.1rem;
  color: white;
  height: 1rem;
}

.more-icon {
  width: 1rem;
  cursor: pointer;
}

#card-details-popover {
  min-width: 9rem;
  top: 10px !important;
}
.popover-links a,
.popover-links div {
  color: #9c9c9c;
  font-size: 0.75rem;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.popover-links a:hover,
.popover-links div:hover {
  color: black;
  text-decoration: none;
  background-color: #faf3e5;
}

.card-field {
  border-radius: 3px;
  border: solid 1px #dedede;
  background-color: #ffffff;
  padding: 0.8rem;
}

.detail-container {
  -webkit-transition: 1s;
  transition: 1s;
}

.card-label {
  font-size: 0.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
}
.btn-cancel {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
}

#add-credit-card .add-card-button {
  font-weight: normal;
  color: var(--primary);
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.81rem;
  padding-bottom: 0.81rem;
  /* margin-top: 2.5rem; */
  border-radius: 0.25rem;
  border: solid 1px #eaedf3;
  background-color: #ffffff;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  overflow: hidden;
  height: 3.2rem;
}

.card-details-active {
  height: 22.2rem !important;
}

#add-credit-card .add-card-button:focus {
  outline: var(--primary);
}

.pricing-card {
  height: 15.56rem;
  border-radius: 4px;
  box-shadow: 0 2px 22px 0 rgba(205, 205, 205, 0.51);
  border: solid 1px #eaedf3;
  background-color: #ffffff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.pricing-card .price {
  font-size: 2.5rem;
  font-weight: bold;
  color: #d4af37;
}
.pricing-card:hover {
  /* transform: translateY(-0.5rem); */
  box-shadow: 0 4px 8px 0 hsla(212, 83%, 7%, 0.11);
  border-radius: 3px;
  border: solid 1px #d4af37;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.price-btn {
  border-color: #d4af37;
}

.price-btn:hover {
  background-color: #d4af37;
  color: #ffffff;
}

#email-inputs input.form-control {
    background: none;
    border: none;
    font-size: 1.25rem;
    border-radius: 0;
    border-bottom: 2px solid #fff;
    padding: .5rem 0;
}
#email-inputs.primary input.form-control {
    font-size: 1rem;
    color: #212121;
    height: 3em;
    border-radius: 3px;
    background-color: #fff !important;
    border: solid 1px #dedede;
    padding: 0.375rem 0.75rem;
    box-shadow: none;
    outline: none;
}

#email-inputs .email-input-wrapper::after {
    content: 'Press "enter/return" to add email';
    position: absolute;
    font-size: 0.75rem;
    right: 0;
    bottom: 0.25rem;
}
#email-inputs.primary .email-input-wrapper::after {
    bottom: -1rem;
}
#email-inputs input.form-control::-webkit-input-placeholder { color: #fff; }
#email-inputs input.form-control::-moz-placeholder { color: #fff; }
#email-inputs input.form-control::-ms-input-placeholder { color: #fff; }
#email-inputs input.form-control::placeholder { color: #fff; }
#email-inputs input.form-control:focus { 
    color: #fff;
    border-color: var(--primary);
}

#email-inputs.primary input.form-control::-webkit-input-placeholder { color: #6c757c; }

#email-inputs.primary input.form-control::-moz-placeholder { color: #6c757c; }

#email-inputs.primary input.form-control::-ms-input-placeholder { color: #6c757c; }

#email-inputs.primary input.form-control::placeholder { color: #6c757c; }
#email-inputs.primary input.form-control:focus { 
    color: #212121;
}

.selected-emails span {
    background: #fff;
    color: #212121;
    padding: 0.25rem 0.825rem;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: 600;
    margin-right: .5rem;
    margin-bottom: .5rem;
}
.selected-emails span > img {
    height: .625rem;
    margin-left: .25rem;
    margin-bottom: .1rem;
    cursor: pointer;
}

#email-inputs.primary .selected-emails span {
    background: #eaedf3;
    color: #212121;
}
#link-business-complete h3 {
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
}
#link-business-complete p {
    font-size: 1rem;
    text-align: center;
    padding: 0 4rem;
    margin-bottom: 3.5rem;
}
#link-business-complete img {
    max-height: 8rem;
    margin-bottom: 2.5rem;
}
.btn.gt-btn-link{
    background: #fff;
}

/* LINK EXIST BUSINESS */
#link-exist-business {
    /* margin-top: -10rem; */
}

#link-exist-business .or-text {
    font-size: 1rem;
    margin: 0;
    text-align: center; 
}

.link-practitioner-container{
    max-height: 100%;
    overflow: auto;
}


.lds-ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid var(--primary);
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.75s;
            animation-delay: -0.75s;
  }
  @-webkit-keyframes lds-ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
  @keyframes lds-ripple {
    0% {
      top: 28px;
      left: 28px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 58px;
      height: 58px;
      opacity: 0;
    }
  }
  
#verify-email-page {
    height: 100vh;
    background: #fff;
}
#verify-email-page h3 {
    color: #212121;
    font-size: 1.375rem;
    font-weight: bold;
}
#verify-email-page p { color: #212121 }
.load {
  -webkit-animation: loadContainer 0.3s ease;
          animation: loadContainer 0.3s ease;
}

@-webkit-keyframes loadContainer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loadContainer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.qstn-answr h6{
    font-size: 1.312rem;
    cursor: pointer;
}
.qstn-answr svg{
    cursor: pointer;
}
.qstn-answr:hover{
    fill:#515456;
    opacity: 0.8
}
.qstn-answr svg{
    cursor: pointer;
}

#profile-skeleton {
    background-color: #f2f4f8;
    min-height: 100vh;
}
#terms-and-service .termsofservice{
    font-size: 1.8rem;
    font-weight: bold;
    color: #212121;
    margin-top: 3rem;
}
#terms-and-service .introduction {
    font-size: 1rem;
    font-weight: bold;
    color: #212121;
    margin-top: 2rem;
  }
  #terms-and-service .terms {
    font-size: 1rem;
    color: #716d6d;
  }
  #terms-and-service .terms a{
    border-bottom: 5px;
  }
  #terms-and-service .list-item{
      margin-top: 1rem;
      font-weight: normal;
      color: #212121;
  }
  #terms-and-service .list-item-active{
    margin-top: 1rem;
    font-weight: bold;
    color: #212121;
  }
  #terms-and-service .list-item a{
    color: #212121;
  }
  #terms-and-service .terms-list{
   position: -webkit-sticky;
   position: sticky;
   top: 2rem;
  }
  #terms-and-service .terms a{
   color: #4392f1;
   /* border-bottom: 1px solid #4392f1; */
 }
 #terms-and-service li{
    padding: 0;
  }
#resolving-conflict{
    margin-top: 3rem;
}
#resolving-conflict .title{
    font-size: 1.8rem;
    font-weight: bold;
    color: #212121;
}
#resolving-conflict .instructions-container{
        margin-top: 2rem;
}
#resolving-conflict .instructions{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#resolving-conflict .sub-title{
    font-size: 1rem;
    font-weight: bold;
    color: #212121;
    margin-top: 1.5rem;
}
#resolving-conflict li{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#community-guidelines{
    margin-top: 3rem;
}
#community-guidelines .title{
    font-size: 1.8rem;
    font-weight: bold;
    color: #212121;
}
#community-guidelines .guidelines-info{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
    margin-top: 2rem;
}
#community-guidelines h1{
    font-size: 1rem;
    font-weight: bold;
    color: #212121;
}
#community-guidelines p{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#community-guidelines li{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#community-guidelines .footer{
    margin-top: 3rem;
}
.community-list li {
    list-style: none;
}
.community-list li a {
    color: #9c9c9c !important;
}
#privacy-policy {
    margin-top: 3rem;
}
#privacy-policy .title{
    font-size: 1.8rem;
    font-weight: bold;
    color: #212121;
}
#privacy-policy .privacy-info{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
    margin-top: 2rem;
}
#privacy-policy h1{
    font-size: 1rem;
    font-weight: bold;
    color: #212121;
}
#privacy-policy p{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#privacy-policy li{
    font-size: 1rem;
    font-weight: normal;
    color: #716d6d;
}
#privacy-policy .footer{
    margin-top: 3rem;
}
.search-results {
  background-color: #f2f4f8;
  /* min-height: 100vh; */
}
.search-results .header p {
  font-size: 0.75rem;
  color: #9c9c9c;
}
.search-results .container {
  min-height: 60%;
}
#search-results .practitionerFilters .card{
  border: 1px solid#eaedf3 !important;
}
#search-results .practitionerFilters .card-header {
  background-color: white;
  height: 45px;
  border-bottom: 0;
  cursor: pointer;
}
#search-results .contentFilters .card{
  border: 1px solid#eaedf3 !important;
}
#search-results .contentFilters .card-header {
  background-color: white;
  height: 45px;
  border-bottom: 0;
  cursor: pointer;
}
#search-results .filterHead{
  background-color: white;
  border: 1px solid #eaedf3;
  border-bottom: 0;
  height: 50px;
}
#search-results .FilterHeading{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #212121;
}
#search-results .clearAll{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #d4af37;
  cursor: pointer;
}
#search-results .practitionerFilters .filterList{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #212121;
  text-transform: capitalize;
}
#search-results .practitionerFilters .checkbox{
  width: 11px !important;
  height: 11px !important;
  border-radius: 3px !important;
}
#search-results .practitionerFilters input[type='checkbox']:checked::after{
    background-size: 0.425rem;
}
#search-results .practitionerFilters .input-range__track{
  background-color: lightgray !important;
  height: 0.275rem;
}
#search-results .practitionerFilters .input-range__track--active{
  height: 0.75rem;
  top: -75%;
  background: #d4af37 !important;
}
#search-results .practitionerFilters .input-range__slider{
  margin-top: -1.25rem;
}
#search-results .practitionerFilters .input-range__label-container{
  color: #212121 !important;
}
#search-results .practitionerFilters .input-range__label-container::after{
  display: none !important;
}
#search-results .practitionerFilters .input-range__label--value .input-range__label-container{
  display: none;
}
#search-results .practitionerFilters .priceInput{
  width: 90px;
  height: 32px;
  border-radius: 3px;
  border: solid 1px #dedede;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #212121;
}
#search-results .practitionerFilters .priceInputDiv{
  margin-top: 32px;
}
#search-results .practitionerFilters .priceTo{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
  margin-bottom: 0;
}
#search-results .practitionerFilters .clearALL{
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  /* text-align: right; */
  width: 50px;
  height: 14px;
  color: #212121;
  background-color: #dedede;
  padding-top: 2px;
  cursor: pointer;
}
#search-results .contentFilters .clearALL{
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  /* text-align: right; */
  width: 50px;
  height: 14px;
  color: #212121;
  background-color: #dedede;
  padding-top: 2px;
  cursor: pointer;
}
#search-results .practitionerFilters .closeClearAll{
  color: #979797;
  padding-left: 3px;
  padding-right: 2px;
}
#search-results .contentFilters .closeClearAll{
  color: #979797;
  padding-left: 3px;
  padding-right: 2px;
}
#search-results .contentSearchHead{
  background-color: #ffffff;
}
#search-results .contentSearchHeader{
  background-color: #ffffff;
  padding: 17px 0;
  border-bottom: 2px solid #eaedf3;
}
#search-results .contentSearchHeader .contentType{
  height: 35px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
}
#search-results .contentSearchHeader .contentType .heading{
  opacity: 0.5;
  cursor: pointer;
}
#search-results .contentSearchHeader .contentType .filterDot{
  font-size: 25px;
  position: absolute;
  margin-top: 20px;
  font-weight: bold;
  /* opacity: 1 !important; */
  color: #d4af37;
}
#search-results .contentSearchFooter{
  padding-top: 17px;
  padding-left: 17px;
}
#search-results .contentSearchFooter .footerFilterTextDiv .footerFilterText{
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
  cursor: pointer;
}
#search-results .contentSearchFooter .footerFilterTextDivActive .footerFilterText{
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
}
#search-results .contentSearchFooter .footerFilterTextDiv{
  padding-bottom: 15px;
}
#search-results .contentSearchFooter .footerFilterTextDivActive{
  padding-bottom: 15px;
  border-bottom: 2px solid #d4af37;
}
/* #search-results .contentFilters{
  padding: 20px 10px 20px 10px;
  background-color: #ffffff;
  border: 1px solid #eaedf3;;
} */
#search-results .contentFilters .checkbox{
  width: 11px !important;
  height: 11px !important;
  border-radius: 3px !important;
  /* border: solid 1px #031121 !important; */
}
#search-results .contentFilters input[type='checkbox']:checked::after{
    background-size: 0.425rem;
}
#search-results .contentFilters .filterList{
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #212121;
  text-transform: capitalize;
}
#search-results .contentFilters .filterListCount{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #9c9c9c;
}
#search-results .contentSearchHeader .contentType .activeContentType{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #d4af37;
  opacity: 1;
}
#search-results .practitionerFilters .filterListCount{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #9c9c9c;
}
#search-results .viewMore{
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #d4af37;
}
#search-results .textFilter{
  border: 0;
  border-bottom: 2px solid #d4af37;
  margin-left: -12px;
  padding-left: 15px;
}
#search-results .locationDiv .form-control{
  border: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  /* border-bottom: 2px solid #d4af37 !important; */
}
/* #search-results .locationDiv .autocomplete-dropdown-container{
  border: 0 !important;
  border-bottom: 2px solid #d4af37 !important;
} */
#search-results .scrollFilterList{
  float: left;
  width: 100%;
  overflow-y: auto;
  max-height: 450px;
}
#search-results .scrollFilterList::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#search-results .scrollFilterList::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
#search-results .scrollFilterList::-webkit-scrollbar-thumb {
  background: #d4af37; 
}

/* Handle on hover */
#search-results .scrollFilterList::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

#settings-list-item {
    border: solid 0.016rem #eaedf3;
    background-color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    color: #212121;
  }
  #settings-list-item svg{
      height: 1.25rem;
      margin-right: 0.5rem;
 }
.settings-link{
    color: #212121;
    width: 100%;
    display: block;
    padding: 1rem 1.5rem;
    padding-right: 1rem;
}
#settings-list-item .premium-membership{
    float: right;
    border-radius: 0.87rem;
    border: solid var(--primary) 0.06rem;
    padding: 0.125rem 0.125rem;
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    color: #212121;
}
.settings-link:hover{
    color: var(--primary);
}
.settings-list-item-active{ 
    position: relative;
    font-size: 1rem;
    font-weight: 600;
    color: var(--primary);
    background-color: #fdfbf3;
}
.settings-list-item-active svg path {
    stroke: var(--primary);
  }
.settings-list-item-active::before{
   position: absolute;
   content: '';
   left: 0;
   top: 0;
   bottom: 0;
   border: 0.1rem solid var(--primary)
}

  #user-settings-options .dropdown{
    -webkit-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
  }
  .dropdown-toggle{
      width: 100%;
  }
  #user-settings-options .dropdown-menu{
    width: 100%;
    z-index: 2000;
    -webkit-transform-origin: top;
            transform-origin: top;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    /* transition: all 0.5s ease-in-out; */
    /* overflow: hidden; */
  }

  /* .show > .dropdown-menu {
    max-height: 900px;
    visibility: visible;
  } */

  #user-settings-options .dropdown-menu a{
      color: #212121;
      text-align: center;
  }
  /* #user-settings-options #dropdown-options a{
    color: #212121;
    text-align: center;
  } */
  #user-settings-options #dropdown-options a:active{
      color: red;
  }

  #settings-dropdown-options .dropdown-toggle{
    background-color: var(--primary);
    padding: 0.625rem 1rem;
    border-color: #eaedf3;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  #settings-dropdown-options .dropdown-divider { margin: 0;}
  #settings-dropdown-options .dropdown-item { padding: 0.625rem 1rem; }
  #settings-dropdown-options .dropdown-item.active { 
    background-color: var(--primary);
    color: #ffffff;
    font-weight: 600;
  }

  
#user-settings {
  padding-top: 1.25rem;
}
#user-settings .title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0;
  height: 2.8rem;
}
#user-settings .settings-list {
  list-style-type: none;
  padding: 0;
  /* margin-top: 1rem; */
  border-radius: 0.2rem;
}
#user-settings .user-info {
  background-color: #ffffff;
  /* margin-top: 3.87rem; */
  padding: 2rem;
  border-radius: 0.2rem;
}
#user-settings .general-settings {
  margin-top: 1rem;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 0.2rem;
}
#settings-expanded #save-button {
  display: "flex";
  justify-content: "flex-end";
  padding-right: 0;
}
.change-password {
  font-size: 1.3rem;
  font-weight: bold;
}
#user-settings .subtitle {
  font-size: 0.8rem;
  color: #9c9c99;
  font-weight: bold;
}
.user-settings-footer {
  padding-top: 15rem;
}

.update-password-button {
  height: 3rem;
  border-radius: 0.2rem;
  border: solid 0.09rem var(--primary);
  margin-top: 1.25rem;
  padding: 0.75rem 1.45rem;
}
.update-password-button:active {
  font-weight: bold;
}
/* profile-settings */
#profile-settings .profile-details {
  background-color: #ffffff;
  margin-top: 1rem;
  padding: 2rem;
  border-radius: 0.2rem;
}
#profile-settings .profile-form {
  margin-top: 1.5rem;
}

#profile-settings .sub-title {
  font-size: 0.75rem;
  font-weight: bold;
  color: #9c9c9c;
}
#profile-settings .delete-account-button {
  padding: 0;
  margin-top: 1.5rem;
}
#profile-settings .delete-button-title {
  margin-left: 0.25rem;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: normal;
  color: #9c9c9c;
  padding-left: 0;
}
#profile-settings .settings-title {
  font-size: 1.27rem;
  font-weight: bold;
  color: #212121;
}

/* notifications-settings */
#notifications-settings {
  padding: 2rem;
  /* margin-top: 3.87rem; */
  background-color: #ffffff;
  border-radius: 0.2rem;
}
#notifications-settings .notification-settings-title {
  font-size: 1.3rem;
  font-weight: bold;
  font-stretch: normal;
  color: #212121;
}
#notifications-settings .notification-settings-description {
  font-size: 1rem;
  font-weight: normal;
  color: #9c9c9c;
  margin-bottom: 1.5rem;
}

/* Membership */
#membership-settings {
  /* margin-top: 3.87rem; */
  background-color: #ffffff;
  border-radius: 0.2rem;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

#membership-settings .membership-title {
  font-size: 1.27rem;
  font-weight: bold;
  text-align: center;
  color: #212121;
  margin-top: 2.2rem;
}
#membership-settings .membership-subtitle {
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  color: #9c9c9c;
}
#membership-settings .view-plans-button {
  padding: 0.625rem 1.56rem;
  border: solid 0.09rem var(--primary);
  border-radius: 0.18rem;
  margin-top: 1.43rem;
}

#membership-settings .membership-plan {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-image: linear-gradient(275deg, #d49437 100%, #d4af37);
  color: white;
}

#membership-settings .cancel-border {
  border-bottom: solid 1px #eaedf3;
}

#membership-settings .subscription-close {
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#membership-settings .subscription-close:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  padding-right: 0.3rem;
}

/* payment-info-settings */
#payment-info-settings {
  /* margin-top: 3.87rem; */
  background-color: #ffffff;
  border-radius: 0.2rem;
  padding: 2.56rem 2rem;
}
#payment-info-settings .saved-cards {
  font-size: 1.31rem;
  font-weight: bold;
  color: #212121;
}
#payment-info-settings .cards-description {
  font-weight: normal;
  color: #9c9c9c;
}
#payment-info-settings .add-card-button {
  font-weight: normal;
  color: var(--primary);
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.81rem;
  padding-bottom: 0.81rem;
  margin-top: 2.5rem;
  border-radius: 0.25rem;
  border: solid 1px #eaedf3;
  background-color: #ffffff;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  overflow: hidden;
  height: 3.2rem;
}

.card-details-active {
  height: 22.2rem !important;
}

#payment-info-settings .add-card-button:focus {
  outline: var(--primary);
}

/* personal-asessment-settings */
#personal-asessment-settings {
  /* margin-top: 3.87rem; */
  background-color: #ffffff;
  border-radius: 0.2rem;
  padding: 2.56rem 2rem;
}
#personal-asessment-settings .assement-title {
  font-size: 1.31rem;
  font-weight: bold;
  color: #212121;
}
#personal-asessment-settings .assement-subtitle {
  font-size: 1rem;
  font-weight: normal;
  color: #9c9c9c;
}
#personal-asessment-settings .asessment-practitioner-list {
  margin-top: 2.5rem;
}
#personal-asessment-settings .shared-practitioner-image {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
  border-radius: 1.25rem;
}
.shared-status {
  font-size: 0.75rem;
  font-weight: normal;
  color: #9c9c9c;
}
.shared-prac-category {
  text-transform: capitalize;
  font-size: 0.75rem;
  color: #9c9c99;
  font-weight: normal;
}
.shared-prac-category span:not(:last-child)::after {
  content: ", ";
}

/* order-history */
#order-history {
  /* margin-top: 3.87rem; */
  background-color: #ffffff;
  border-radius: 0.2rem;
  padding: 1.625rem 2rem;
}
#order-history .order-history-title {
  font-size: 1.31rem;
  font-weight: bold;
  color: #212121;
}
#order-history .order-history-subtitle {
  font-size: 1rem;
  font-weight: normal;
  color: #9c9c9c;
}
#order-history .order-history-list {
  margin-top: 2.5rem;
}

.plan-description {
  font-size: 0.75rem;
}

.plan-tick-icon {
  width: 2rem;
}

.price-card-skelton {
  height: 15.56rem;
  border-radius: 4px;
  box-shadow: 0 2px 22px 0 rgba(205, 205, 205, 0.51);
  border: solid 1px #eaedf3;
  background-color: #ffffff;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.member-skelton {
  height: 5rem;
  border-radius: 4px;
  border: solid 1px #eaedf3;
  background-color: #ffffff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

nav.payment-tab {
  border-color: #eaedf3;
}
.payment-tab.nav-tabs .nav-link{
  border: none;
  border-radius: 0;
  color: #a2a2a2;
  font-weight: 700;
}
.payment-tab.nav-tabs .nav-link.active{ 
  color: #222222;
  outline: none;
  border-bottom: 4px solid var(--primary)
}
.payment-tab .nav-link {
  padding: 0;
  padding-bottom: 1rem;
  margin-bottom: 0;
  margin-right: 1rem;
}
.payment-tab .title {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

@media( max-width: 576px) {
  .card-details-active {
    height: 30rem !important;
  }
}
@media(min-width: 992px) {
  .save-changes { margin-top: -3.75rem;}
}


/* Business settings */
#practitioner-business-settings{
    /* margin-top: 1.87rem; */
}
#practitioner-business-settings .practitioner-details{
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
#practitioner-business-settings .profile-header{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
#practitioner-business-settings .profile-form{
    margin-top: 2.5rem;
  }

#practitioner-business-settings .profile-image{
    margin-right: 2rem;
}
#practitioner-business-settings .delete-picture{
    margin-left: 1rem;
    border: 0;
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
    outline: 0;
}
.form-sub-title{
    font-size: 0.75rem;
    font-weight: bold;
    color: #9c9c9c;
}
.delete-account-button{
    padding: 0;
    margin-top: 1.5rem;
}
.delete-button-title{
    margin-left: 0.25rem;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
    padding-left: 0; 
}

.practitioner-time-details{
    padding: 2rem;
    background-color: #ffffff;
    /* margin-top: 1.25rem; */
    border-radius: 0.2rem;
}
.practitioner-profile-image{
    height: 2.5rem;
    width: 2.5rem;
    object-fit: cover;
    border-radius: 50%;
}
.practitioner-time-title{
    font-size: 1.27rem;
    font-weight: bold;
    color: #212121;
}
.practitioner-time-subtitle{
    font-weight: normal;
    color: #9c9c9c;
}
.practitioner-time-table{
    margin-top: 3rem;
}
.practitioner-table-head{
    font-weight: 600;
}
.practitioner-name{
    font-size: 1rem;
    font-weight: normal;
    color: #292929;
}
.practitioner-email{
  font-size: 0.75rem;
  font-weight: normal;
  color: #9c9c9c; 
}
.practitioner-date-added{
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
}


/* Promotions */
#promotions-settings{
    /* margin-top: 3.87rem; */
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
#promotions-settings .promotions-title{
    font-size: 1.27rem;
    font-weight: bold;
    color: #212121;
}
.promo-modal-title{
    text-align: center;
    font-size: 1.21rem;
    font-weight: bold;
    color: #212121;
}
.promo-label{
    font-size: 0.75rem;
    font-weight: bold;
    color: #9c9c9c;
}
.promo-modal-description{
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    color: #9c9c9c;
  }
.empty-promotion-container{
    padding: 4rem 0;
}
.promotion-card{
    height: 12rem;
    border-radius: 0.2rem;
    /* background-color: rgba(185, 148, 88, 0.21); */
    /* padding: 1rem; */
    border: 1px solid #BFBFBF;
}
.promo-popover{
    background-color: #ffffff;
    padding: 0.25rem;
    padding-right: 2.4rem;
    border-radius: 0.2rem;
}
.promo-popover-item-delete{
  font-size: 0.75rem;
  font-weight: normal;
  color: #ff0000;
  padding: 0;
  text-align: left;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; */
}
.promo-popover-item-delete:hover{
    color: #ff0000;
}
.promo-popover-item-delete svg{
    height: 0.75rem;
}
.promo-popover-item-edit{
    font-size: 0.75rem;
    font-weight: normal;
    padding: 0;
    text-align: left;
  }
  .promo-popover-item-edit svg{
      height: 0.75rem;
  }
.promo-options{
    position: absolute;
    top: 0.5rem;
    right: 1rem;
}
.promotion-card-description{
    font-size: 1.27rem;
    font-weight: bold;
    color: #b99458;
    margin-left: 1rem;
}
.promotion-end-date{
    font-size: 0.75rem;
    font-weight: normal;
    color: #9c9c9c;
    position: absolute;
    bottom: 0.75rem;
}
.promotion-end-date-container{
    border-radius: 0.75rem;
    background-color: rgba(185, 148, 88, 0.21);;
    font-size: 0.75rem;
    font-weight: normal;
    color: #212121;
    padding-left: 0.5rem;
    padding: 0.25rem 0.5rem;
    position: absolute;
    bottom: 0.5rem;
    right: 1.5rem;
}
/* Shared-results */
#shared-results{
    /* margin-top: 3.87rem; */
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
.shared-results-title{
    font-size: 1.27rem;
    font-weight: bold;
    color: #212121;
}
.shared-results-subtitle{
    font-weight: normal;
    color: #9c9c9c; 
}

/* Sales-history */
#sales-history{
    /* margin-top: 3.87rem; */
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}

/* Blocked-accounts */
#blocked-accounts{
    /* margin-top: 3.87rem; */
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
#blocked-accounts .blocked-accounts-title{
    font-size: 1.27rem;
    font-weight: bold;
    color: #212121;
}
#blocked-accounts .blocked-accounts-subtitle{
    font-weight: normal;
    color: #9c9c9c;
}
.blocked-accounts-table{
    margin-top: 2.5rem;
}

.promotion-card .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 19px;
}

.promotion-card .switch input {
    display: none;
}
.promotion-card label.switch::before{
    display: none;
}

.promotion-card .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.promotion-card .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.promotion-card input:checked + .slider {
    background-color: #2ab934;
}

.promotion-card input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

.promotion-card input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
}

.promotion-card .slider.round {
    border-radius: 34px;
}

.promotion-card .slider.round:before {
    border-radius: 50%;
    background-color: #BFBFBF;
}
.promotion-card input:checked + .slider.round:before {
    background-color: white;
}
/* Profile settings */
#practitioner-profile-settings .practitioner-profile-settings-details{
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
#practitioner-profile-settings .delete-picture{
    margin-left: 1rem;
    border: 0;
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
    outline: 0;
}
#practitioner-profile-settings .profile-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: #ffffff; */
}
.professional-services{
    margin-top: 1.25rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 0.2rem;
}
.professional-services .professional-title{
  font-size: 1.27rem;
  font-weight: bold;
  color: #212121;
}
#practitioner-profile-settings .profile-image{
    margin-right: 2rem;
}

#practitioner-profile-settings .sub-title{
    font-size: 0.75rem;
    font-weight: bold;
    color: #9c9c9c;
}
#practitioner-profile-settings .specialities-description{
    font-size: 0.75rem;
    font-weight: normal;
    color: #9c9c9c;
}
#practitioner-profile-settings .delete-button-title{
    margin-left: 0.5rem;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
    padding-left: 0; 
}
#practitioner-profile-settings .time-description{
    font-size: 1rem;
    font-weight: normal;
    color: #9c9c9c;
}
.set-time{
    font-size: 1rem;
    font-weight: bold;
    color: #121212;
    margin-left: 0.5rem;
}
#practitioner-profile-settings .professional-settings{
    flex: 1 1;
}
@media (max-width: 768px) { 
    #practitioner-profile-settings .practitioner-profile-settings-details{ padding: 1rem; }
    .professional-services { padding: 1rem; } 
}
.validation-badge {
    position: absolute;
    right: .75rem;
    top: .75rem;
    z-index: 2;
    font-size: 1.5rem;
    /* transform: translateY(-50%); */
}
.validation-badge.material {
    font-size: 1.5rem;
    right: 0;
}
#forgot-password img {
    width: 11.75rem;
    margin-bottom: 2rem;
    padding-left: 3.75rem;
}
#forgot-password form {
    padding: 3.75rem;
}
#forgot-password .link-sent-message {
    padding: 0.925rem 3.75rem;
    background-color: rgba(212, 175, 55, 0.164);
}
#reset-password img {
    width: 11.75rem;
    margin-bottom: 2rem;
    padding-left: 3.75rem;
}
#reset-password form {
    padding: 3.75rem;
}
.line-height {
  line-height: 1.5;
}

.star {
  font-family: "icon-star";
  font-size: 0.5rem;
}

.author-name {
  font-size: 0.75rem;
}
.article-icon {
  width: 1.32rem;
  height: 1.32rem;
  position: absolute;
  top: 2%;
  left: 2%;
}

.audio-icon {
  width: 1.32rem;
  height: 1.32rem;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 100;
}

.premium {
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: 100;
}

.feed-profile {
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  object-fit: cover;
}

.feed-description {
  line-height: 1.5;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feed-container {
  /* border-bottom: solid 1px #eaedf3; */
  background-color: #ffffff;
}

.feed-card-1 .article-card {
  height: 34rem;
}
.feed-card-1 .article-card .article-image{
  height: 25rem;
}

.feed-card-2 .article-card {
  height: 23rem;
}
.feed-card-2 .article-card .article-image{
  height: 12rem;
}


.content-card {
  height: 10rem;
  cursor: pointer;
}
.content-card .content-card-image {
  height: 100%;
  width: 10rem;
  min-width: 10rem;
  object-fit: cover;
  position: relative;
}
/* .content-card:hover .content-card-img-container {
  background-image: linear-gradient(to bottom, hsl(212, 83%, 7%), hsla(212, 83%, 7%, 0));
}
.content-card:hover .content-card-image {
  z-index: -10;
} */


@media (max-width: 768px) {
  .feed-card-1 .article-card {
    height: 29rem;
  }
  .feed-card-1 .article-card .article-image{
    height: 20rem;
  }
  .feed-card-2 .article-card {
    height: 20rem;
  }
  .feed-card-2 .article-card .article-image{
    height: 9rem;
  }

  .content-card {
    height: 8rem;
  }
  .content-card .content-card-image {
    width: 8rem;
    min-width: 8rem;
  }
}

@media (max-width: 567px) {
  .feed-card-1 .article-card {
    height: 20rem;
  }
  .feed-card-1 .article-card .article-image{
    height: 9rem;
  }
}

.full-width-feed-img {
  height: 25rem;
  cursor: pointer;
}

.full-width-feed-card-image {
  height: 17rem;
  cursor: pointer;
}
.full-width-feed-card-image img[alt="feeds"] {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.feed-type-two-img {
  height: 11rem;
  cursor: pointer;
}

@media (max-width: 536px) {
  .full-width-feed-img {
    height: 100%;
    cursor: pointer;
  }
  .full-width-feed-card-image {
    height: 100%;
    cursor: pointer;
  }
}

.top-right {
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 536px) {
  .section-hide {
    display: none !important;
  }
}
.checkout-container {
  min-height: 61vh;
}

.checkout-container .price-plan-detail {
  border-radius: 4px;
  background-image: linear-gradient(275deg, #d49437 100%, #d4af37);
  color: white;
}

.checkout-container .price-plan-detail-overlay {
  width: 100%;
  position: absolute;
  opacity: 0.8;
  border-radius: 4px;
  background-image: linear-gradient(310deg, #d49437, #d4af37);
  z-index: -9;
}

.checkout-container .price-plan-detail-image {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -10;
}

.nav-tabs {
  /* background-color: #777777 !important; */
  border-bottom: solid 1px #f2f4f8;
}

.checkout-details {
  border-radius: 4px;
  background-color: white;
}

.checkout-container .navitem.active {
  background-color: #777777;
}

.checkout-container .nav-tabs a {
  color: #9c9c9c !important;
}

.checkout-container .nav-tabs .nav-link:hover {
  border-color: transparent;
}
.checkout-container .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: black !important;
  border-color: transparent;
  /* border-bottom: solid 4px #d4af37; */
  background-color: transparent;
}

.checkout-container .nav-tabs .nav-link,
.nav-tabs .nav-item.show .nav-link {
  padding-left: 0px;
  padding-right: 3rem;
}

.checkout-container .success-pop {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-animation: 0.8s successPop ease;
          animation: 0.8s successPop ease;
}

@-webkit-keyframes successPop {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes successPop {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.status-banner {
    position: absolute;
    background-color: #f00;
    color: #fff;
    left: 0;
    right: 0;
    top: 0;
    width: 100vw;
    height: 64px;
    padding: 0 1.5rem;
    z-index: 2020;
}
