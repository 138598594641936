.radial-chart {
    position: relative;
    display: inline-block;
    transition: all 0.3s ease-in;
}
.radial-chart.no-progress .radial-chart-progress {
    opacity: 0;
}
.radial-chart-total {
    opacity: 0.3;
}
.radial-chart-progress {
    transform: rotate(0deg);
    transform-origin: center;
    transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
    transition-delay: 0.3s;
}
.radial-chart-title{
    font-size: 1rem;
    color: #212121;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: -0.625rem;
    /* position: absolute;
    left:50%;
    transform: translateX(-50%);
    bottom:-8px; */
}