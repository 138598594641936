#prac_profile .prac_location_map .map{
  height: 15rem;
  border: solid 1px #eaedf3;
}
#prac_profile .prac_location_map .ol-overlaycontainer-stopevent{
  position: absolute;
  display: none;
}
#prac_profile .prac_location_map .map_details h6{
  color: #031121;
  font-weight: 700;
}
#prac_profile .prac_location_map .map_details p{
  color: #808080;
  font-size: 0.7rem;
}
#prac_profile .prac_location_map .prac_location_body{
  background-color: #fff;
  border: solid 1px #eaedf3;
  border-top: none;
}
#prac_profile .prac_location_map .map_icon{
  background-color: #d4af37;
  border-radius: 50%;
}