.status-banner {
    position: absolute;
    background-color: #f00;
    color: #fff;
    left: 0;
    right: 0;
    top: 0;
    width: 100vw;
    height: 64px;
    padding: 0 1.5rem;
    z-index: 2020;
}