#user-profile {
  background-color: #f2f4f8;
  min-height: 100vh;
}
.heading {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 37px;
  line-height: 47px;
  color: #212121;
}
.questions {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #031121;
  width: 100%;
}
.answer {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #9c9c9c;
}
.custom-select {
  font-size: 16px;
  border: 1px solid #d4af37 !important;
}
.nopadding {
  padding: 0 !important;
}
.result-title {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: #212121;
}
.score-title {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 37px;
  line-height: 56px;
  color: #212121;
}
.optional{
  margin-left: 15px;
  width: 96%;
}
.showbtn{
    background-color:white;
    display: block;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    color:#d4af37;
    border:none
}


