.search-results {
  background-color: #f2f4f8;
  /* min-height: 100vh; */
}
.search-results .header p {
  font-size: 0.75rem;
  color: #9c9c9c;
}
.search-results .container {
  min-height: 60%;
}
#search-results .practitionerFilters .card{
  border: 1px solid#eaedf3 !important;
}
#search-results .practitionerFilters .card-header {
  background-color: white;
  height: 45px;
  border-bottom: 0;
  cursor: pointer;
}
#search-results .contentFilters .card{
  border: 1px solid#eaedf3 !important;
}
#search-results .contentFilters .card-header {
  background-color: white;
  height: 45px;
  border-bottom: 0;
  cursor: pointer;
}
#search-results .filterHead{
  background-color: white;
  border: 1px solid #eaedf3;
  border-bottom: 0;
  height: 50px;
}
#search-results .FilterHeading{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #212121;
}
#search-results .clearAll{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #d4af37;
  cursor: pointer;
}
#search-results .practitionerFilters .filterList{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #212121;
  text-transform: capitalize;
}
#search-results .practitionerFilters .checkbox{
  width: 11px !important;
  height: 11px !important;
  border-radius: 3px !important;
}
#search-results .practitionerFilters input[type='checkbox']:checked::after{
    background-size: 0.425rem;
}
#search-results .practitionerFilters .input-range__track{
  background-color: lightgray !important;
  height: 0.275rem;
}
#search-results .practitionerFilters .input-range__track--active{
  height: 0.75rem;
  top: -75%;
  background: #d4af37 !important;
}
#search-results .practitionerFilters .input-range__slider{
  margin-top: -1.25rem;
}
#search-results .practitionerFilters .input-range__label-container{
  color: #212121 !important;
}
#search-results .practitionerFilters .input-range__label-container::after{
  display: none !important;
}
#search-results .practitionerFilters .input-range__label--value .input-range__label-container{
  display: none;
}
#search-results .practitionerFilters .priceInput{
  width: 90px;
  height: 32px;
  border-radius: 3px;
  border: solid 1px #dedede;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #212121;
}
#search-results .practitionerFilters .priceInputDiv{
  margin-top: 32px;
}
#search-results .practitionerFilters .priceTo{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
  margin-bottom: 0;
}
#search-results .practitionerFilters .clearALL{
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  /* text-align: right; */
  width: 50px;
  height: 14px;
  color: #212121;
  background-color: #dedede;
  padding-top: 2px;
  cursor: pointer;
}
#search-results .contentFilters .clearALL{
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.93;
  letter-spacing: normal;
  /* text-align: right; */
  width: 50px;
  height: 14px;
  color: #212121;
  background-color: #dedede;
  padding-top: 2px;
  cursor: pointer;
}
#search-results .practitionerFilters .closeClearAll{
  color: #979797;
  padding-left: 3px;
  padding-right: 2px;
}
#search-results .contentFilters .closeClearAll{
  color: #979797;
  padding-left: 3px;
  padding-right: 2px;
}
#search-results .contentSearchHead{
  background-color: #ffffff;
}
#search-results .contentSearchHeader{
  background-color: #ffffff;
  padding: 17px 0;
  border-bottom: 2px solid #eaedf3;
}
#search-results .contentSearchHeader .contentType{
  height: 35px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
}
#search-results .contentSearchHeader .contentType .heading{
  opacity: 0.5;
  cursor: pointer;
}
#search-results .contentSearchHeader .contentType .filterDot{
  font-size: 25px;
  position: absolute;
  margin-top: 20px;
  font-weight: bold;
  /* opacity: 1 !important; */
  color: #d4af37;
}
#search-results .contentSearchFooter{
  padding-top: 17px;
  padding-left: 17px;
}
#search-results .contentSearchFooter .footerFilterTextDiv .footerFilterText{
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
  cursor: pointer;
}
#search-results .contentSearchFooter .footerFilterTextDivActive .footerFilterText{
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
}
#search-results .contentSearchFooter .footerFilterTextDiv{
  padding-bottom: 15px;
}
#search-results .contentSearchFooter .footerFilterTextDivActive{
  padding-bottom: 15px;
  border-bottom: 2px solid #d4af37;
}
/* #search-results .contentFilters{
  padding: 20px 10px 20px 10px;
  background-color: #ffffff;
  border: 1px solid #eaedf3;;
} */
#search-results .contentFilters .checkbox{
  width: 11px !important;
  height: 11px !important;
  border-radius: 3px !important;
  /* border: solid 1px #031121 !important; */
}
#search-results .contentFilters input[type='checkbox']:checked::after{
    background-size: 0.425rem;
}
#search-results .contentFilters .filterList{
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #212121;
  text-transform: capitalize;
}
#search-results .contentFilters .filterListCount{
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #9c9c9c;
}
#search-results .contentSearchHeader .contentType .activeContentType{
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #d4af37;
  opacity: 1;
}
#search-results .practitionerFilters .filterListCount{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #9c9c9c;
}
#search-results .viewMore{
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #d4af37;
}
#search-results .textFilter{
  border: 0;
  border-bottom: 2px solid #d4af37;
  margin-left: -12px;
  padding-left: 15px;
}
#search-results .locationDiv .form-control{
  border: 0 !important;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  /* border-bottom: 2px solid #d4af37 !important; */
}
/* #search-results .locationDiv .autocomplete-dropdown-container{
  border: 0 !important;
  border-bottom: 2px solid #d4af37 !important;
} */
#search-results .scrollFilterList{
  float: left;
  width: 100%;
  overflow-y: auto;
  max-height: 450px;
}
#search-results .scrollFilterList::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#search-results .scrollFilterList::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
#search-results .scrollFilterList::-webkit-scrollbar-thumb {
  background: #d4af37; 
}

/* Handle on hover */
#search-results .scrollFilterList::-webkit-scrollbar-thumb:hover {
  background: #555; 
}