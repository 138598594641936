.card-field {
  border-radius: 3px;
  border: solid 1px #dedede;
  background-color: #ffffff;
  padding: 0.8rem;
}

.detail-container {
  transition: 1s;
}

.card-label {
  font-size: 0.75rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
}
.btn-cancel {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9c9c9c;
}

#add-credit-card .add-card-button {
  font-weight: normal;
  color: var(--primary);
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.81rem;
  padding-bottom: 0.81rem;
  /* margin-top: 2.5rem; */
  border-radius: 0.25rem;
  border: solid 1px #eaedf3;
  background-color: #ffffff;
  transition: 0.8s;
  overflow: hidden;
  height: 3.2rem;
}

.card-details-active {
  height: 22.2rem !important;
}

#add-credit-card .add-card-button:focus {
  outline: var(--primary);
}
