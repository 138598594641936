#prac_profile .prac_offers {
  background-color: #ffffff;
}
#prac_profile .prac_offers_header {
  padding: 1rem;
  border: 1px solid #eaedf3;
  border-bottom: 0;
}
#prac_profile .prac_offers_header h6{
 font-weight: 600;
}
#prac_profile .prac_offers_body_container {
  padding: 1rem;
  border: 1px solid #eaedf3;
}
#prac_profile .prac_offers_body {
  background-color: #EEA84936;
  padding-top: 0;
  background-image: url('/icons/percentage.svg');
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: 174px -19px;
  background-size: 65px;
  border-radius: 3px;
}
#prac_profile .prac_offers_body:not(:last-child){
  margin-bottom: 1rem;
}
#prac_profile .prac_offers_body h6{
  color: #b99458;
  font-weight: 600;
  font-size: 0.9rem;
}
#prac_profile .prac_offers_body p{
  color: #b99458;
  font-size: 0.75rem;
  margin-bottom: 0;
} 
#prac_profile .prac_offers_body .date{
  background-color: #B99458;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
} 
@media (max-width: 576px) {
  #prac_profile .prac_offers_body {
    background-position: right -19px;
  }
}